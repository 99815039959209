import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import {
  LOGIN_BAP,
  ERROR_API,
  LOGIN_BAP_SUCCESS,
  REFRESH_TOKEN,
} from "./actionTypes"
import { postLogin, postRefreshWeb } from "helpers/fakebackend_helper"
import { loginBapSuccess, errorApi, refreshTokenError } from "./actions"
import axios from "axios"

function* loginBap({ payload: { user, history } }) {
  try {
    const data = { nopeg: user.nopeg, password: user.password }
    const response = yield call(postLogin, data)
    yield put(loginBapSuccess(response))
    // console.log(response)
    localStorage.setItem("authUser", response.token)
    history("/dashboard")
  } catch (e) {
    yield put(errorApi(e))
  }
}

function* refreshToken({ payload: data }) {
  try {
    const response = yield call(postRefreshWeb, data)
    // console.log("response refresh token >>>", response)
    yield put(loginBapSuccess(response))
    localStorage.setItem("authUser", response.token)
  } catch (e) {
    console.log(e)
    if (e.response.status === 403) {
      localStorage.removeItem("authUser")
      yield put(refreshTokenError(e))
    }
  }
}

function* authenticationSaga() {
  yield takeEvery(LOGIN_BAP, loginBap)
  yield takeEvery(REFRESH_TOKEN, refreshToken)
}

export default authenticationSaga
