import {
  ADD_TUGAS,
  ADD_TUGAS_FAIL,
  ADD_TUGAS_SUCCESS,
  GET_TUGAS,
  GET_TUGAS_FAIL,
  GET_TUGAS_SUCCESS,
  GET_ELEMENT_JSON,
  GET_ELEMENT_JSON_FAIL,
  GET_ELEMENT_JSON_SUCCESS,
  DELETE_TUGAS,
  DELETE_TUGAS_LAPORAN,
} from "./actionTypes"
import {
  getTugas,
  getElementJsonSuccess,
  getElementJsonFail,
  addTugasSuccess,
  addTugasFail,
  getTugasFail,
  getTugasSuccess,
  deleteTugas,
  deleteTugasFail,
  deleteTugasSuccess,
  deleteTugasLaporanFail,
  deleteTugasLaporanSuccess,
  addNumLaporan,
  deleteNumLaporan,
} from "./actions"
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects"
import {
  getJsonElement,
  postAddTugas,
  getTugasBe,
  deleteTugasBe,
  deleteTugasLaporanBe,
} from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { func } from "prop-types"
const page = state => state.tugas.page
const limit = state => state.tugas.limit
const cari = state => state.tugas.cari
const idTugas = state => state.tugas.idTugasToDelete

function* getJsonSaga() {
  try {
    // console.log("masuk get json di saga >>>")
    const response = yield call(getJsonElement)
    // console.log("response tugas di saga >>>", response)
    yield put(getElementJsonSuccess(response))
  } catch (error) {
    if (error.response) {
      yield put(getElementJsonFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan data ", { autoClose: 2000 })
    }
    // console.log("error tugas di saga >>>", error)
  }
}

function* addTugasSaga({ payload: action }) {
  try {
    // console.log("masuk add tugas di saga >>>")
    // console.log("action add tugas di saga >>>", action)
    const response = yield call(postAddTugas, action)
    // console.log("response add tugas di saga >>>", response)
    toast.success("Berhasil menambahkan tugas", { autoClose: 2000 })
    yield put(addTugasSuccess())
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
    }
    // yield call(getTugasSaga, { payload: payloadGet })
    yield put(getTugas(payloadGet))
  } catch (error) {
    if (error.response) {
      yield put(addTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menambahkan tugas ", { autoClose: 2000 })
    }
  }
}

function* getTugasSaga({ payload: action }) {
  try {
    // console.log("action di saga ???", action)
    const response = yield call(getTugasBe, action)
    yield put(getTugasSuccess(response))
  } catch (error) {
    // console.log("error get tugas di saga >>>", error)
    if (error.response) {
      yield put(getTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan tugas ", { autoClose: 2000 })
    }
  }
}

function* deleteTugasSaga({ payload: action }) {
  try {
    // console.log("action delete tugas di saga >>>", action)
    const response = yield call(deleteTugasBe, action)
    // console.log("response delete tugas di saga >>>", response)
    yield put(deleteTugasSuccess())
    if (response.rc === "00") {
      toast.success("Berhasil menghapus tugas", { autoClose: 2000 })
      const pageState = yield select(page)
      const limitState = yield select(limit)
      const cariState = yield select(cari)

      const payloadGet = {
        limit: limitState,
        page: pageState,
        cari: cariState,
      }
      yield put(getTugas(payloadGet))
    } else if (response.rc === "01") {
      // console.log("masuk di rc 01")
      yield put(addNumLaporan(parseInt(response.message)))
      // HANDLE ACTION BUAT NAMPILIN JUMLAH LAPORAN DAN DELETE
    }
  } catch (error) {
    // console.log("error delete tugas di saga >>>", error)
    if (error.response) {
      yield put(deleteTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menghapus tugas ", { autoClose: 2000 })
    }
  }
}

function* deleteTugasLaporanSaga({ payload: action }) {
  const idTugasState = yield select(idTugas)
  // console.log("idTugasState >>>", idTugasState)
  try {
    const responseDeleteTugasLaporan = yield call(
      deleteTugasLaporanBe,
      idTugasState
    )
    // console.log(
    //   "response delete tugas laporan di saga >>>",
    //   responseDeleteTugasLaporan
    // )
    yield put(deleteTugasLaporanSuccess())
    toast.success("Berhasil menghapus tugas dan laporan", { autoClose: 2000 })
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
    }
    yield put(getTugas(payloadGet))
    yield put(deleteNumLaporan())
  } catch (error) {
    // console.log("error delete tugas laporan di saga >>>", error)
    if (error.response) {
      yield put(deleteTugasLaporanFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menghapus tugas dan laporan ", { autoClose: 2000 })
    }
  }
}

function* tugasSaga() {
  yield takeEvery(GET_ELEMENT_JSON, getJsonSaga)
  yield takeEvery(ADD_TUGAS, addTugasSaga)
  yield takeEvery(GET_TUGAS, getTugasSaga)
  yield takeEvery(DELETE_TUGAS, deleteTugasSaga)
  yield takeEvery(DELETE_TUGAS_LAPORAN, deleteTugasLaporanSaga)
}

export default tugasSaga
