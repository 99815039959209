import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://apikalsel.ptbap.net"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["token"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  const response = await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
  
  return response
  // console.log('kontol')
  // return await axiosApi
  //   .post(url, { ...data }, { ...config })
  //   .then((response) => response.data)
  // .catch((err) => console.log('err di api_helper >>>', err))
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
