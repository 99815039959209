import {
  GET_TEMUAN,
  GET_TEMUAN_SUCCESS,
  GET_TEMUAN_FAIL,
  GET_RUANGAN,
  GET_RUANGAN_SUCCESS,
  GET_RUANGAN_FAIL,
  CLEAR_TEMUAN,
  POST_VALIDASI,
  POST_VALIDASI_SUCCESS,
  POST_VALIDASI_FAIL,
  POST_SELESAI,
  POST_SELESAI_SUCCESS,
  POST_SELESAI_FAIL,
} from "./actionTypes"

const initialState = {
  loadingGetRuangan: false,
  loadingGetTemuan: false,
  ruangan: null,
  temuan: null,
  errorGetRuangan: null,
  errorGetTemuan: null,
  pageRuangan: 0,
  limitRuangan: 10,
  cariRuangan: "",
  pageTemuan: 0,
  limitTemuan: 10,
  cariTemuan: "",
  idRuangan: "",
  loadingPostValidasi: false,
  loadingPostSelesai: false,
  errorPostValidasi: null,
  errorPostSelesai: null,
}

const temuanAdmin = (state = initialState, action) => {
  switch (action.type) {
    case GET_RUANGAN:
      state = {
        ...state,
        loadingGetRuangan: true,
        pageRuangan: action.payload.page,
        limitRuangan: action.payload.limit,
        cariRuangan: action.payload.cari,
      }
      break
    case GET_RUANGAN_SUCCESS:
      state = {
        ...state,
        loadingGetRuangan: false,
        ruangan: action.payload,
      }
      break
    case GET_RUANGAN_FAIL:
      state = {
        ...state,
        loadingGetRuangan: false,
        errorGetRuangan: action.payload,
      }
      break
    case GET_TEMUAN:
      state = {
        ...state,
        loadingGetTemuan: true,
        pageTemuan: action.payload.page,
        limitTemuan: action.payload.limit,
        cariTemuan: action.payload.cari,
        idRuangan: action.payload.idRuangan,
      }
      break
    case GET_TEMUAN_SUCCESS:
      state = {
        ...state,
        loadingGetTemuan: false,
        temuan: action.payload,
      }
      break
    case GET_TEMUAN_FAIL:
      state = {
        ...state,
        loadingGetTemuan: false,
        errorGetTemuan: action.payload,
      }
      break
    case CLEAR_TEMUAN:
      state = {
        ...state,
        temuan: null,
      }
      break
    case POST_VALIDASI:
      state = {
        ...state,
        loadingPostValidasi: true,
      }
      break
    case POST_VALIDASI_SUCCESS:
      state = {
        ...state,
        loadingPostValidasi: false,
      }
      break
    case POST_VALIDASI_FAIL:
      state = {
        ...state,
        loadingPostValidasi: false,
        errorPostValidasi: action.payload,
      }
      break
    case POST_SELESAI:
      state = {
        ...state,
        loadingPostSelesai: true,
      }
      break
    case POST_SELESAI_SUCCESS:
      state = {
        ...state,
        loadingPostSelesai: false,
      }
      break
    case POST_SELESAI_FAIL:
      state = {
        ...state,
        loadingPostSelesai: false,
        errorPostSelesai: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default temuanAdmin
