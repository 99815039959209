import {
  POST_TEMUAN,
  POST_TEMUAN_FAIL,
  POST_TEMUAN_SUCCESS,
  GET_TEMUAN,
  GET_TEMUAN_FAIL,
  GET_TEMUAN_SUCCESS,
} from "./actionTypes"

export const postTemuan = data => {
  return {
    type: POST_TEMUAN,
    payload: data,
  }
}

export const postTemuanSuccess = data => {
  return {
    type: POST_TEMUAN_SUCCESS,
    payload: data,
  }
}

export const postTemuanFail = data => {
  return {
    type: POST_TEMUAN_FAIL,
    payload: data,
  }
}

export const getTemuan = data => {
  return {
    type: GET_TEMUAN,
    payload: data,
  }
}

export const getTemuanSuccess = data => {
  return {
    type: GET_TEMUAN_SUCCESS,
    payload: data,
  }
}

export const getTemuanFail = data => {
  return {
    type: GET_TEMUAN_FAIL,
    payload: data,
  }
}
