import { LOGIN_BAP, ERROR_API, LOGIN_BAP_SUCCESS, REFRESH_TOKEN, REFRESH_TOKEN_ERROR } from "./actionTypes";

export const loginBap = (user, history) => {
    return {
      type: LOGIN_BAP,
      payload: { user, history },
    }
  }

export const errorApi = (data) => {
  return {
    type: ERROR_API,
    payload: data
  }
}

export const loginBapSuccess = (user) => {
  return {
    type: LOGIN_BAP_SUCCESS,
    payload: user
  }
}

export const refreshToken = (token) => {
  return {
    type: REFRESH_TOKEN,
    payload: token
  }
} 

export const refreshTokenError = (data) => {
  return {
    type: REFRESH_TOKEN_ERROR,
    payload: data
  }
} 