import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  addTugas,
  getElementJson,
  getTugas,
  deleteTugas,
  deleteTugasLaporan,
  clearIdTugas,
  deleteNumLaporan,
} from "store/actions"
import { ToastContainer } from "react-toastify"
import DataTable from "./dataTable"
import classnames from "classnames"
import CustomTable from "components/Common/CustomTable"

const Tugas = props => {
  document.title = "BAP"
  const dispatch = useDispatch()
  const loadingAddTugas = useSelector(state => state.tugas.loadingAdd)
  const loadingGetTugas = useSelector(state => state.tugas.loadingGet)
  const errorAddElemen = useSelector(state => state.elemen.errorAdd)
  const errorGetElemen = useSelector(state => state.elemen.errorGet)
  const elementJson = useSelector(state => state.tugas.elemenJson)
  const loadingJson = useSelector(state => state.tugas.loadingGetJson)
  const loadingDelete = useSelector(state => state.tugas.loadingDelete)
  const numLaporan = useSelector(state => state.tugas.numLaporanToDelete)
  const listArea = elementJson !== null ? elementJson.data.area : []
  const listRuang = elementJson !== null ? elementJson.data.ruangan : []
  const listPekerjaan = elementJson !== null ? elementJson.data.pekerjaan : []
  const listBarang = elementJson !== null ? elementJson.data.barang : []
  const listTarget = elementJson !== null ? elementJson.data.target : []
  const listParameter = elementJson !== null ? elementJson.data.parameter : []
  const tugas = useSelector(state => state.tugas.tugas)
  const dummyNumLaporan = 3
  // console.log("numLaporan >>>", numLaporan)
  // console.log("elementJson >>>>", elementJson)
  // const elements = useSelector(state => state.elemen.elements)
  const page = useSelector(state => state.tugas.page)
  const limit = useSelector(state => state.tugas.limit)
  const cari = useSelector(state => state.tugas.cari)
  const [elemen, setElemen] = useState("area")
  const [nama, setNama] = useState("")
  const [area, setArea] = useState("")
  const [ruang, setRuang] = useState("")
  const [pekerjaan, setPekerjaan] = useState("")
  const [barang, setBarang] = useState("")
  const [target, setTarget] = useState("")
  const [parameter, setParameter] = useState("")
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [modalConfirmDeleteLaporan, setModalConfirmDeleteLaporan] =
    useState(false)
  const [idTugasToDelete, setIdTugasToDelete] = useState(null)
  // const [page, setPage] = useState(0)
  // const [limit, setLimit] = useState(10)
  // const [cari, setCari] = useState("")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const listElemen = [
    { key: 0, label: "area", value: "area" },
    { key: 1, label: "ruang", value: "ruang" },
    { key: 2, label: "pekerjaan", value: "pekerjaan" },
    { key: 3, label: "barang", value: "barang" },
    { key: 4, label: "target", value: "target" },
    { key: 5, label: "parameter", value: "parameter" },
  ]

  const validation = useFormik({
    // enableReinitialize: true,
    initialValues: {
      idArea: area,
      idRuang: ruang,
      idPekerjaan: pekerjaan,
      idBarang: barang,
      idTarget: target,
      idParameter: parameter,
    },

    validationSchema: Yup.object({
      idArea: Yup.string().required("Harap isi area"),
      idRuang: Yup.string().required("Harap isi ruangan"),
      idPekerjaan: Yup.string().required("Harap isi pekerjaan"),
      idBarang: Yup.string().required("Harap isi barang"),
      idTarget: Yup.string().required("Harap isi target"),
      idParameter: Yup.string().required("Harap isi parameter"),
    }),

    onSubmit: values => {
      // console.log("values useFormik >>>", values)
      dispatch(addTugas(values))
      validation.resetForm()
    },
  })

  useEffect(() => {
    dispatch(getElementJson())
    dispatch(getTugas({ limit: limit, cari: cari, page: page }))
  }, [])

  useEffect(() => {
    if (numLaporan > 0) {
      setModalConfirmDeleteLaporan(true)
    }
  }, [numLaporan])

  const column = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "id Tugas",
      accessorFn: row => row._id,
    },
    {
      header: "Area",
      accessorFn: row => row.area.namaArea,
    },
    {
      header: "Ruangan",
      accessorFn: row => row.ruangan.namaRuangan,
    },
    {
      header: "Pekerjaan",
      accessorFn: row => row.pekerjaan.namaPekerjaan,
    },
    {
      header: "Barang",
      accessorFn: row => row.barang.namaBarang,
    },
    {
      header: "Target",
      accessorFn: row => row.target.target,
    },
    {
      header: "Parameter",
      accessorFn: row => row.parameter.parameter,
    },
    {
      header: "Detail",
      // cell: ({ row }) => <span>Detail Button</span>,
      cell: ({ row }) => {
        return (
          <Button
            type="button"
            color="danger"
            className="btn-sm btn-rounded"
            onClick={() => {
              // console.log("HARUSNYA MASUK HAPUS SIH INI")
              setModalConfirmDelete(true)
              setIdTugasToDelete(row._id)
            }}
            // style={{ alignItems: "center" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              Hapus
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </div>
          </Button>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Kebersihan" breadcrumbItem="Tugas" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          // console.log("MASUK SINI GA NIG")
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="flex-grow-1 ">
                          <CardTitle className="h4">Buat tugas</CardTitle>
                          <CardSubtitle className="card-title-desc">
                            Berdasarkan elemen yang telah anda buat
                          </CardSubtitle>
                          <div className="justify-content-center align-items-center">
                            {loadingJson === true && elementJson === null ? (
                              <Spinner size="lg" color="primary" />
                            ) : (
                              <div>
                                {/* area */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih area
                                  </label>
                                  <select
                                    id="elemen-selector-area"
                                    className="form-select"
                                    // defaultValue={area}
                                    value={validation.values.idArea}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idArea",
                                        seletedValue
                                      )
                                      setArea(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih area --
                                    </option>
                                    {(listArea || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idArea ? (
                                    <p className="text-danger">
                                      * {validation.errors.idArea}
                                    </p>
                                  ) : null}
                                </div>
                                {/* ruangan */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih ruangan
                                  </label>
                                  <select
                                    id="elemen-selector-ruangan"
                                    className="form-select"
                                    // defaultValue={ruang}
                                    value={validation.values.idRuang}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idRuang",
                                        seletedValue
                                      )
                                      setRuang(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih ruangan --
                                    </option>
                                    {(listRuang || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idRuang &&
                                  validation.touched.idRuang ? (
                                    <p className="text-danger">
                                      * {validation.errors.idRuang}
                                    </p>
                                  ) : null}
                                </div>
                                {/* pekerjaan */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih pekerjaan
                                  </label>
                                  <select
                                    id="elemen-selector-pekerjaan"
                                    className="form-select"
                                    defaultValue={pekerjaan}
                                    value={validation.values.idPekerjaan}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idPekerjaan",
                                        seletedValue
                                      )
                                      setPekerjaan(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih pekerjaan --
                                    </option>
                                    {(listPekerjaan || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idPekerjaan &&
                                  validation.touched.idPekerjaan ? (
                                    <p className="text-danger">
                                      * {validation.errors.idPekerjaan}
                                    </p>
                                  ) : null}
                                </div>
                                {/* barang */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih barang
                                  </label>
                                  <select
                                    id="elemen-selector-barang"
                                    className="form-select"
                                    defaultValue={barang}
                                    value={validation.values.idBarang}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idBarang",
                                        seletedValue
                                      )
                                      setBarang(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih barang --
                                    </option>
                                    {(listBarang || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idBarang &&
                                  validation.touched.idBarang ? (
                                    <p className="text-danger">
                                      * {validation.errors.idBarang}
                                    </p>
                                  ) : null}
                                </div>
                                {/* target */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih target
                                  </label>
                                  <select
                                    id="elemen-selector-target"
                                    className="form-select"
                                    defaultValue={target}
                                    value={validation.values.idTarget}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idTarget",
                                        seletedValue
                                      )
                                      setTarget(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih target --
                                    </option>
                                    {(listTarget || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idTarget &&
                                  validation.touched.idTarget ? (
                                    <p className="text-danger">
                                      * {validation.errors.idTarget}
                                    </p>
                                  ) : null}
                                </div>
                                {/* parameter */}
                                {/* ========================= */}
                                <div className="mb-3">
                                  <label className="form-label">
                                    Pilih parameter
                                  </label>
                                  <select
                                    id="elemen-selector-parameter"
                                    className="form-select"
                                    defaultValue={parameter}
                                    value={validation.values.idParameter}
                                    onChange={event => {
                                      let seletedValue = event.target.value
                                      validation.setFieldValue(
                                        "idParameter",
                                        seletedValue
                                      )
                                      setParameter(seletedValue)
                                    }}
                                  >
                                    <option value="" disabled>
                                      -- Pilih parameter --
                                    </option>
                                    {(listParameter || [])?.map(val => (
                                      <option key={val._id} value={val.value}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.errors.idParameter &&
                                  validation.touched.idParameter ? (
                                    <p className="text-danger">
                                      * {validation.errors.idParameter}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex-grow-1 text-center">
                          <div className="mt-5 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loadingAddTugas}
                            >
                              {/* Tambah */}
                              {loadingAddTugas ? <Spinner size="sm" /> : "Buat"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  <Card>
                    <CardBody>
                      {/* <h5>anjing</h5> */}
                      {/* <div className="flex-grow-1">
                        <h5 className="font-size-15 mt-0 mb-4">
                          Daftar elemen
                        </h5>
                      </div> */}
                      <CardTitle className="h4">Daftar tugas</CardTitle>
                      <CardSubtitle className="card-title-desc">
                        Daftar tugas yang telah dibuat berdasarkan elemen
                      </CardSubtitle>
                      <Row className="text-center justify-content-center align-items-center">
                        {loadingDelete ? (
                          <Spinner size="lg" color="primary" />
                        ) : loadingGetTugas || tugas === null ? (
                          <Spinner size="lg" color="primary" />
                        ) : (
                          <DataTable
                            columns={column}
                            tableClass="table-striped"
                            theadClass="thead-dark"
                            divClassName="table-responsive-sm"
                            isBordered={true}
                            isPagination={true}
                            isGlobalFilter={false}
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            SearchPlaceholder="Cari ..."
                            buttonClass="btn-success"
                            buttonName="Cari"
                            isAddButton={false}
                            isCustomPageSize={true}
                            handleUserClick={() => console.log("yuhu")}
                            isJobListGlobalFilter={false}
                          />
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {idTugasToDelete !== null ? (
        <>
          <Modal
            isOpen={modalConfirmDelete}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            // toggle={() => {
            //   setModalForm(!modalForm)
            // }}
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  setModalConfirmDelete(!modalConfirmDelete)
                }}
              ></ModalHeader>
            </div>
            <ModalBody>
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-delete text-danger"></i>
                  </div>
                </div>

                <Row className="justify-content-center">
                  <Col xl={10}>
                    <h4 className="text-danger">Hapus tugas?</h4>
                    <p className="text-muted font-size-14 mb-4">
                      Anda yakin ingin mengahapus tugas ini secara permanen?
                    </p>

                    <Button
                      type="button"
                      color="danger"
                      className="btn-sm btn-rounded"
                      onClick={() => {
                        dispatch(deleteTugas(idTugasToDelete))
                        setModalConfirmDelete(!modalConfirmDelete)
                        // setModalConfirmDeleteLaporan(true)
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Saya yakin, hapus sekarang!
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : null}

      {/* modal confirm delete tugas laporan */}
      {numLaporan > 0 ? (
        <>
          <Modal
            isOpen={modalConfirmDeleteLaporan}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            // toggle={() => {
            //   setModalForm(!modalForm)
            // }}
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  setModalConfirmDeleteLaporan(!modalConfirmDeleteLaporan)
                  dispatch(deleteNumLaporan())
                }}
              ></ModalHeader>
            </div>
            <ModalBody>
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-alert-box text-danger"></i>
                  </div>
                </div>

                <Row className="justify-content-center">
                  <Col xl={10}>
                    <h4 className="text-danger">Ada laporannya nih!</h4>
                    <p className="text-muted font-size-14 mb-4">
                      Tugas ini sudah memiliki {numLaporan} laporan
                    </p>

                    <Button
                      type="button"
                      color="danger"
                      className="btn-sm btn-rounded"
                      onClick={() => {
                        dispatch(deleteTugasLaporan())
                        setModalConfirmDeleteLaporan(!modalConfirmDeleteLaporan)
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Hapus tugas dan laporannya!
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : null}
      <ToastContainer />
    </React.Fragment>
  )
}

export default Tugas
