import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postLogin,
} from "../../../helpers/fakebackend_helper"
import axios from "axios"
const API_URL = "https://apikalsel.ptbap.net";

const fireBaseBackend = getFirebaseBackend()

// function* loginUser({ payload: { user, history } }) {
//   // try {
//   //   const response = yield call(postLogin, {
//   //     username: user.nopeg,
//   //     password: user.password,
//   //   });
//   //   console.log('Response from saga:', response);
//   // } catch (error) {
//   //   console.log('Error from saga:', error); // This will log the error if the post function throws
//   // }
// }

function* loginUser({ payload: { user, history } }) {
  // console.log('history >>>', history)
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "prod") {
  //     const response = yield call(postLogin, {
  //       username: user.nopeg,
  //       password: user.password,
  //     })
  //     console.log('response login >>>', response)
  //   }
  // } catch (e) {
  //   console.log('error login >>>>', e)
  // }
  try {
    // console.log('KAPAN DIA MASUK SAGA SINI SIH')
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "prod") {
      const response = yield call(postLogin, {
        username: user.nopeg,
        password: user.password,
      });

      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
