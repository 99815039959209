import React, { useState, useEffect, useRef } from "react"
import withRouter from "components/Common/withRouter"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import CarouselPage from "../../AuthenticationInner/CarouselPage"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { postTemuan, getTemuan } from "../../../store/actions"
import { ToastContainer } from "react-toastify"
import DataTable from "./dataTable"

const Temuan = props => {
  document.title = "BAP - Temuan"
  const idRuangan = props.router.params.idRuangan
  const kategori = props.router.params.kategori
  const dispatch = useDispatch()
  const tglMelapor = new Date().getTime()
  const tglMelaporFix = tglMelapor.toString()
  // const tglMelapor = "asu"
  const loadingAdd = useSelector(state => state.temuan.loadingAdd)
  const loadingGet = useSelector(state => state.temuan.loadingGet)
  const page = useSelector(state => state.temuan.page)
  const limit = useSelector(state => state.temuan.limit)
  const cari = useSelector(state => state.temuan.cari)
  const temuan = useSelector(state => state.temuan.temuan)
  // console.log("temuan >>>", temuan)

  const [fotoTemuan, setFotoTemuan] = useState([])
  const [modal, setModal] = useState(false)
  const [modalFoto, setModalFoto] = useState(false)
  const [fotoSelected, setFotoSelected] = useState(null)
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      kategori: kategori,
      idRuangan: idRuangan,
      // tglMelapor: new Date().getTime().toString(),
      keluhan: "",
      nama: "",
      jabatan: "",
      nopeg: "",
      instansi: "",
      fotoSebelum: null,
    },

    validationSchema: Yup.object({
      kategori: Yup.string().required("Harap isi kategori"),
      idRuangan: Yup.string().required("Harap isi ruangan"),
      // tglMelapor: Yup.string().required("Harap isi tanggal melapor"),
      keluhan: Yup.string().required("Harap isi keluhan anda"),
      nama: Yup.string().required("Harap isi nama anda"),
      jabatan: Yup.string(),
      nopeg: Yup.string(),
      instansi: Yup.string(),
      fotoSebelum: Yup.mixed().required("Harap upload foto temuan"),
    }),

    onSubmit: async values => {
      // console.log("values useFormik >>>", values)
      // console.log("tglMelaporFix >>>", tglMelaporFix, typeof tglMelaporFix)
      const formData = new FormData()
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key])
        }
      }
      formData.append("tglMelapor", tglMelaporFix)

      // console.log("formData >>>", formData)
      dispatch(postTemuan(formData))
      validation.resetForm()
      validation.setFieldValue("fotoSebelum", null)
      setFileInputKey(Date.now())
    },
  })

  const column = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "nama",
      accessorFn: row => row.nama,
    },
    {
      header: "kategori",
      accessorFn: row => row.kategori,
    },
    {
      header: "keluhan",
      accessorFn: row => row.keluhan,
    },
    {
      header: "Detail",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              // console.log("row >>>", row)
              setFotoSelected(row.foto_sebelum)
              setModalFoto(true)
              // console.log(row.laporan)
            }}
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
          >
            Lihat
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(
      getTemuan({ limit: limit, cari: cari, page: page, idRuangan: idRuangan })
    )
  }, [])
  //   console.log("params >>>", idRuangan)

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Halo 👋</h5>
                        <p>Ada temuan terkait kebersihan?</p>
                        {/* <p>{credential.nama}</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* {_.isEmpty(errorLogin) ? null : !errorLogin.response ? (
                        <Alert color="danger">
                          Periksa koneksi internet anda
                        </Alert>
                      ) : (
                        <Alert color="danger">
                          {errorLogin.response.data.message}
                        </Alert>
                      )} */}

                      <div className="mb-3">
                        <Label className="form-label">Nama</Label>
                        <Input
                          name="nama"
                          className="form-control"
                          placeholder="Masukkan nama anda"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.nama || ""}
                          invalid={
                            validation.touched.nama && validation.errors.nama
                              ? true
                              : false
                          }
                        />
                        {validation.touched.nama && validation.errors.nama ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nama}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* karyawan */}
                      {kategori === "karyawan" ? (
                        <div>
                          <div className="mb-3">
                            <Label className="form-label">Nomor pegawai</Label>
                            <Input
                              name="nopeg"
                              className="form-control"
                              placeholder="Masukkan nopeg anda"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nopeg || ""}
                              invalid={
                                validation.touched.nopeg &&
                                validation.errors.nopeg
                                  ? true
                                  : false
                              }
                              maxLength={8}
                            />
                            {validation.touched.nopeg &&
                            validation.errors.nopeg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.nopeg}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Jabatan</Label>
                            <Input
                              name="jabatan"
                              className="form-control"
                              placeholder="Masukkan jabatan anda"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.jabatan || ""}
                              invalid={
                                validation.touched.jabatan &&
                                validation.errors.jabatan
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.jabatan &&
                            validation.errors.jabatan ? (
                              <FormFeedback type="invalid">
                                {validation.errors.jabatan}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {/* tamu */}
                      {kategori === "tamu" ? (
                        <div className="mb-3">
                          <Label className="form-label">Instansi</Label>
                          <Input
                            name="instansi"
                            className="form-control"
                            placeholder="Masukkan instansi anda"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.instansi || ""}
                            invalid={
                              validation.touched.instansi &&
                              validation.errors.instansi
                                ? true
                                : false
                            }
                          />
                          {validation.touched.instansi &&
                          validation.errors.instansi ? (
                            <FormFeedback type="invalid">
                              {validation.errors.instansi}
                            </FormFeedback>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Keluhan</Label>
                        <Input
                          name="keluhan"
                          className="form-control"
                          placeholder="Masukkan keluhan anda"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.keluhan || ""}
                          invalid={
                            validation.touched.keluhan &&
                            validation.errors.keluhan
                              ? true
                              : false
                          }
                        />
                        {validation.touched.keluhan &&
                        validation.errors.keluhan ? (
                          <FormFeedback type="invalid">
                            {validation.errors.keluhan}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Foto keluhan</Label>
                        <Input
                          className="form-control"
                          type="file"
                          id="formFile"
                          onChange={e => {
                            // console.log(e.target.files[0])
                            // setFotoTemuan(e.target.files[0])
                            validation.setFieldValue(
                              "fotoSebelum",
                              e.target.files[0]
                            )
                          }}
                          key={fileInputKey}
                        />
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loadingAdd}
                          onClick={() => {
                            // console.log(
                            //   "validation.errors >>>",
                            //   validation.values
                            // )
                            // console.log(
                            //   "tglMelaporFix >>>",
                            //   tglMelaporFix,
                            //   typeof tglMelaporFix
                            // )
                            // console.log("kirim")
                            // console.log("tglMelapor >>>", tglMelapor.toString())
                            // console.log(new Date().getTime())
                          }}
                        >
                          {loadingAdd ? <Spinner size="sm" /> : "Kirim"}
                        </button>
                      </div>
                    </Form>
                    <div className="mt-4 d-grid">
                      <button
                        className="btn bg-primary-subtle btn-block"
                        type="submit"
                        disabled={loadingGet}
                        onClick={() => {
                          setModal(true)
                        }}
                      >
                        {loadingGet ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            Temuan di ruangan ini{" "}
                            <Badge color="danger" className="badge-circle">
                              {temuan === null ? 0 : temuan.totalDocs}
                            </Badge>{" "}
                            {/* Example badge */}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} PT. Bina Area Persada</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setModal(!modal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setModal(!modal)
            }}
          ></ModalHeader>
        </div>
        <ModalBody>
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-information"></i>
              </div>
            </div>

            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary mb-5">Temuan di ruangan ini</h4>
                {loadingGet || temuan === null ? (
                  <Spinner size="lg" color="primary" />
                ) : (
                  <DataTable
                    columns={column}
                    tableClass="table-striped"
                    theadClass="thead-dark"
                    divClassName="table-responsive-sm"
                    isBordered={true}
                    isPagination={true}
                    isGlobalFilter={false}
                    paginationWrapper="pagination-wrapper"
                    SearchPlaceholder="Cari id laporan ..."
                    buttonClass="btn-success"
                    buttonName="Cari"
                    isAddButton={false}
                    isCustomPageSize={true}
                    handleUserClick={() => console.log("yuhu")}
                    isJobListGlobalFilter={false}
                    idRuangan={idRuangan}
                  />
                )}
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalFoto}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setModalFoto(!modalFoto)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setModalFoto(!modalFoto)
            }}
          ></ModalHeader>
        </div>
        <ModalBody>
          <div className="text-center mb-4">
            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary mb-5">Foto temuan</h4>
                {fotoSelected === null ? (
                  <Spinner size="lg" color="primary" />
                ) : (
                  <img
                    src={fotoSelected}
                    className="img-fluid"
                    alt="Responsive image"
                  />
                )}
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(Temuan)
