import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  addElemen,
  addElemenFail,
  addElemenSuccess,
  getElemen,
  changeCari,
  changeCariSuccsess,
  changeLimit,
  changeLimitSuccsess,
  changePage,
  changePageSuccsess,
} from "store/actions"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import CustomTable from "components/Common/CustomTable"
import { donwloadNfc } from "../../../helpers/fakebackend_helper"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Elemen = props => {
  document.title = "BAP"
  const dispatch = useDispatch()
  const loadingAddElemen = useSelector(state => state.elemen.loadingAdd)
  const loadingGetElemen = useSelector(state => state.elemen.loadingGet)
  const errorAddElemen = useSelector(state => state.elemen.errorAdd)
  const errorGetElemen = useSelector(state => state.elemen.errorGet)
  const elements = useSelector(state => state.elemen.elements)
  const page = useSelector(state => state.elemen.page)
  const limit = useSelector(state => state.elemen.limit)
  const cari = useSelector(state => state.elemen.cari)
  // console.log("elemen di elemen >>>>", elements)
  const [elemen, setElemen] = useState("area")
  const [nama, setNama] = useState("")
  // const [page, setPage] = useState(0)
  // const [limit, setLimit] = useState(10)
  // const [cari, setCari] = useState("")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const listElemen = [
    { key: 0, label: "area", value: "area" },
    { key: 1, label: "ruangan", value: "ruang" },
    { key: 2, label: "pekerjaan", value: "pekerjaan" },
    { key: 3, label: "barang", value: "barang" },
    { key: 4, label: "target", value: "target" },
    { key: 5, label: "parameter", value: "parameter" },
  ]

  const validation = useFormik({
    // enableReinitialize: true,
    initialValues: {
      elemen: elemen,
      nama: nama,
    },

    validationSchema: Yup.object({
      elemen: Yup.string().required("Harap isi elemen"),
      nama: Yup.string().required("Harap isi nama elemen"),
    }),

    onSubmit: values => {
      dispatch(addElemen(values))
      validation.resetForm()
    },
  })

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(
      getElemen(
        verticalActiveTab === "1"
          ? { tipe: "area", limit: limit, cari: cari, page: page }
          : verticalActiveTab === "2"
          ? { tipe: "ruang", limit: limit, cari: cari, page: page }
          : verticalActiveTab === "3"
          ? { tipe: "pekerjaan", limit: limit, cari: cari, page: page }
          : verticalActiveTab === "4"
          ? { tipe: "barang", limit: limit, cari: cari, page: page }
          : verticalActiveTab === "5"
          ? { tipe: "barang", limit: limit, cari: cari, page: page }
          : { tipe: "parameter", limit: limit, cari: cari, page: page }
      )
    )
  }, [])

  const downloadNfc = async data => {
    // console.log("data >>>", data)
    const token = localStorage.getItem("authUser")
    await axios({
      url: `https://fxv09sx5-9030.asse.devtunnels.ms/webArea/getNfcArea?idArea=${data.idArea}`,
      method: "get",
      headers: { token: token },
      responseType: "blob",
    })
      .then(res => {
        // console.log("res >>>", res)
        const file = new Blob([res.data], { type: "application/pdf" })
        const url = window.URL.createObjectURL(file)
        // window.open(url);
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${data.namaArea}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        // console.log("err >>>", err)
      })
  }

  const downloadQr = async data => {
    console.log("data >>>", data)
    const token = localStorage.getItem("authUser")
    await axios({
      url: `https://apisimba.ptbap.net/webTemuan/getQrCodePdfRuangan?idRuangan=${data.idRuangan}`,
      method: "get",
      headers: { token: token },
      responseType: "blob",
    })
      .then(res => {
        console.log("res print qr >>>", res)
        const file = new Blob([res.data], { type: "application/pdf" })
        const url = window.URL.createObjectURL(file)
        // window.open(url);
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${data.namaRuangan}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        console.log("err print qr>>>", err)
      })
  }

  const columnsArea = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Area",
      accessorFn: row => row.idArea,
    },
    {
      header: "Nama Area",
      accessorFn: row => row.namaArea,
    },
    {
      header: "NFC",
      cell: ({ row }) => {
        return (
          <Button
            onClick={async () => {
              // console.log("row >>>", row)
              downloadNfc({ idArea: row.idArea, namaArea: row.namaArea })
              // const response = await donwloadNfc(row.idArea)
              // console.log("response >>>", response)
            }}
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
          >
            Print
          </Button>
        )
      },
    },
  ]

  const columnsRuang = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Ruangan",
      accessorFn: row => row.idRuangan,
    },
    {
      header: "Nama Ruangan",
      accessorFn: row => row.namaRuangan,
    },
    {
      header: "QR Code",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() =>
              downloadQr({
                idRuangan: row.idRuangan,
                namaRuangan: row.namaRuangan,
              })
            }
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
          >
            Print
          </Button>
        )
      },
    },
  ]

  const columnsPekerjaan = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Pekerjaan",
      accessorFn: row => row.idPekerjaan,
    },
    {
      header: "Nama Pekerjaan",
      accessorFn: row => row.namaPekerjaan,
    },
  ]

  const columnsBarang = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Barang",
      accessorFn: row => row.idBarang,
    },
    {
      header: "Nama Barang",
      accessorFn: row => row.namaBarang,
    },
  ]

  const columnsTarget = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Target",
      accessorFn: row => row.idTarget,
    },
    {
      header: "Target",
      accessorFn: row => row.target,
    },
  ]

  const columnsParameter = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "ID Paramenter",
      accessorFn: row => row.idParameter,
    },
    {
      header: "Nama Paramenter",
      accessorFn: row => row.parameter,
    },
  ]

  const handleUserClick = () => {
    // Handle Add Button Click
    console.log("Add new area")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Kebersihan" breadcrumbItem="Elemen" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="flex-grow-1 ">
                          <CardTitle className="h4">Buat elemen</CardTitle>
                          <CardSubtitle className="card-title-desc">
                            Pilih elemen dan isikan nama elemen
                          </CardSubtitle>
                          <select
                            id="elemen-selector"
                            className="form-select"
                            defaultValue={elemen}
                            value={validation.values.elemen}
                            onChange={event => {
                              let seletedValue = event.target.value
                              validation.setFieldValue("elemen", seletedValue)
                              setElemen(seletedValue)
                              toggleVertical(
                                seletedValue === "area"
                                  ? "1"
                                  : seletedValue === "ruang"
                                  ? "2"
                                  : seletedValue === "pekerjaan"
                                  ? "3"
                                  : seletedValue === "barang"
                                  ? "4"
                                  : seletedValue === "target"
                                  ? "5"
                                  : "6"
                              )
                              dispatch(
                                getElemen({
                                  tipe: seletedValue,
                                  limit: limit,
                                  cari: cari,
                                  page: page,
                                })
                              )
                              // console.log('seletedValue >>>', seletedValue)
                            }}
                          >
                            {(listElemen || [])?.map(val => (
                              <option key={val.key} value={val.value}>
                                {val.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex-grow-1">
                          <div className="mb-3 mt-3">
                            {/* <Label className="form-label">Nama</Label> */}
                            <Input
                              name="nama"
                              className="form-control"
                              placeholder={
                                elemen === "target"
                                  ? "Masukkan target"
                                  : "Masukkan nama " + elemen
                              }
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nama || ""}
                              invalid={
                                validation.touched.nama &&
                                validation.errors.nama
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.nama &&
                            validation.errors.nama ? (
                              <FormFeedback type="invalid">
                                {validation.errors.nama}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex-grow-1 text-center">
                          <div className="mt-5 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loadingAddElemen}
                            >
                              {/* Tambah */}
                              {loadingAddElemen ? (
                                <Spinner size="sm" />
                              ) : (
                                "Buat"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  <Card>
                    <CardBody>
                      {/* <h5>anjing</h5> */}
                      {/* <div className="flex-grow-1">
                        <h5 className="font-size-15 mt-0 mb-4">
                          Daftar elemen
                        </h5>
                      </div> */}
                      <CardTitle className="h4">Daftar elemen</CardTitle>
                      <CardSubtitle className="card-title-desc">
                        Daftar elemen yang telah dibuat dan dapat anda buat
                        menjadi tugas pada menu tugas
                      </CardSubtitle>
                      <Row>
                        <Col md="3">
                          <Nav pills className="flex-column">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleVertical("1")
                                  dispatch(
                                    getElemen({
                                      tipe: "area",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("area")
                                }}
                              >
                                Area
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleVertical("2")
                                  dispatch(
                                    getElemen({
                                      tipe: "ruang",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("ruang")
                                }}
                              >
                                Ruangan
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleVertical("3")
                                  dispatch(
                                    getElemen({
                                      tipe: "pekerjaan",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("pekerjaan")
                                }}
                              >
                                Pekerjaan
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "4",
                                })}
                                onClick={() => {
                                  toggleVertical("4")
                                  dispatch(
                                    getElemen({
                                      tipe: "barang",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("barang")
                                }}
                              >
                                Barang
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "5",
                                })}
                                onClick={() => {
                                  toggleVertical("5")
                                  dispatch(
                                    getElemen({
                                      tipe: "target",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("target")
                                }}
                              >
                                Target
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "mb-2": true,
                                  active: verticalActiveTab === "6",
                                })}
                                onClick={() => {
                                  toggleVertical("6")
                                  dispatch(
                                    getElemen({
                                      tipe: "parameter",
                                      limit: 10,
                                      cari: "",
                                      page: 0,
                                    })
                                  )
                                  // setElemen("parameter")
                                }}
                              >
                                Parameter
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col
                          md="9"
                          className="text-center justify-content-center align-items-center"
                        >
                          {loadingGetElemen || elements === null ? (
                            <Spinner size="lg" color="primary" />
                          ) : (
                            // <div></div>
                            <CustomTable
                              columns={
                                verticalActiveTab === "1"
                                  ? columnsArea
                                  : verticalActiveTab === "2"
                                  ? columnsRuang
                                  : verticalActiveTab === "3"
                                  ? columnsPekerjaan
                                  : verticalActiveTab === "4"
                                  ? columnsBarang
                                  : verticalActiveTab === "5"
                                  ? columnsTarget
                                  : columnsParameter
                              }
                              tipe={
                                verticalActiveTab === "1"
                                  ? "area"
                                  : verticalActiveTab === "2"
                                  ? "ruang"
                                  : verticalActiveTab === "3"
                                  ? "pekerjaan"
                                  : verticalActiveTab === "4"
                                  ? "barang"
                                  : verticalActiveTab === "5"
                                  ? "target"
                                  : "parameter"
                              }
                              // dataParsed={elements}
                              apiUrl="https://fxv09sx5-9030.asse.devtunnels.ms/webArea/getAllArea" // Replace with your actual API endpoint
                              tableClass="table-striped"
                              theadClass="thead-dark"
                              divClassName="table-responsive-sm"
                              isBordered={true}
                              isPagination={true}
                              isGlobalFilter={true}
                              paginationWrapper="pagination-wrapper"
                              SearchPlaceholder="Cari ..."
                              buttonClass="btn-success"
                              buttonName="Cari"
                              isAddButton={true}
                              isCustomPageSize={true}
                              handleUserClick={handleUserClick}
                              isJobListGlobalFilter={false}
                            />
                            // ===========================================================================
                            // <TabContent
                            //   activeTab={verticalActiveTab}
                            //   className="text-muted mt-4 mt-md-0"
                            // >
                            //   <TabPane tabId="1">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Area</th>
                            //             <th>Nama Area</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idArea}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idArea}</td>
                            //               <td>{item.namaArea}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            //   <TabPane tabId="2">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Ruangan</th>
                            //             <th>Nama Ruangan</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idRuangan}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idRuangan}</td>
                            //               <td>{item.namaRuangan}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            //   <TabPane tabId="3">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Pekerjaan</th>
                            //             <th>Nama Pekerjaan</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idPekerjaan}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idPekerjaan}</td>
                            //               <td>{item.namaPekerjaan}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            //   <TabPane tabId="4">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Barang</th>
                            //             <th>Nama Barang</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idBarang}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idBarang}</td>
                            //               <td>{item.namaBarang}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            //   <TabPane tabId="5">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Target</th>
                            //             <th>Nama Target</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idTarget}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idTarget}</td>
                            //               <td>{item.target}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            //   <TabPane tabId="6">
                            //     <div className="table-responsive">
                            //       <Table className="table mb-0">
                            //         <thead className="table-light">
                            //           <tr>
                            //             <th>#</th>
                            //             <th>Id Paramenter</th>
                            //             <th>Nama Paramenter</th>
                            //           </tr>
                            //         </thead>
                            //         <tbody>
                            //           {elements.map((item, index) => (
                            //             // console.log("item tabel >>>", item)
                            //             <tr key={item.idParameter}>
                            //               <th scope="row">{index + 1}</th>
                            //               <td>{item.idParameter}</td>
                            //               <td>{item.parameter}</td>
                            //             </tr>
                            //           ))}
                            //         </tbody>
                            //       </Table>
                            //     </div>
                            //   </TabPane>
                            // </TabContent>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Elemen
