import React, { Fragment, useEffect, useState } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { Link } from "react-router-dom"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table"
// import JobListGlobalFilter from "./GlobalSearchFilter" // If you have a custom global filter component
import {
  changeCari,
  changeCariSuccsess,
  changeLimit,
  changeLimitSuccsess,
  changePage,
  changePageSuccsess,
  getElemen,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const DebouncedInput = ({
  value: initialValue,
  onChange,
  // debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // useEffect(() => {
  //   // const timeout = setTimeout(() => {
  //   //   onChange(value)
  //   // }, debounce)
  //   return () => clearTimeout(timeout)
  // }, [onChange, value])

  return (
    <Col sm={4}>
      <input
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </Col>
  )
}

const CustomTable = ({
  columns,
  apiUrl,
  // dataParsed,
  tipe,
  tableClass = "table-class",
  theadClass = "thead-class",
  divClassName = "table-responsive",
  isBordered = true,
  isPagination = true,
  isGlobalFilter = false,
  paginationWrapper = "pagination-wrapper",
  SearchPlaceholder = "Search...",
  buttonClass = "btn-primary",
  buttonName = "Add New",
  isAddButton = true,
  isCustomPageSize = true,
  handleUserClick,
  isJobListGlobalFilter = false,
}) => {
  const dispatch = useDispatch()
  const elements = useSelector(state => state.elemen.elements)
  const page = useSelector(state => state.elemen.page)
  const limit = useSelector(state => state.elemen.limit)
  const cari = useSelector(state => state.elemen.cari)
  const loadingGetElemen = useSelector(state => state.elemen.loadingGet)
  const dataUsed = elements.data
  const [cariLokal, setCariLokal] = useState(cari)

  const table = useReactTable({
    columns,
    data: dataUsed,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(elements.totalDocs / limit),
    manualGlobalFilter: true,
    state: {
      globalFilter: cari,
      pagination: {
        pageIndex: page,
        pageSize: limit,
      },
    },
    onPaginationChange: state => {
      let payload = {
        tipe: tipe,
        limit: limit,
        cari: cari,
        page: state(table.getState().pagination).pageIndex,
      }
      dispatch(getElemen(payload))
    },
    onGlobalFilterChange: value => {
      console.log("value cari >>>", value)
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    getState,
  } = table

  const handleClickCari = () => {
    dispatch(
      getElemen({
        tipe: tipe,
        limit: limit,
        cari: cariLokal,
        page: page,
      })
    )
  }

  return (
    <>
      {loadingGetElemen ? null : (
        <Fragment>
          <Row className="mb-2">
            {isCustomPageSize && (
              <Col sm={3}>
                <select
                  className="form-select pageSize mb-2 flex-grow-1"
                  value={limit}
                  onChange={e =>
                    // console.log(
                    //   "value >>>",
                    //   e.target.value,
                    //   typeof e.target.value
                    // )
                    // setLimit(Number(e.target.value))
                    dispatch(
                      getElemen({
                        tipe: tipe,
                        limit: parseInt(e.target.value),
                        cari: cari,
                        page: page,
                      })
                    )
                  }
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Tampilkan {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              // <DebouncedInput
              //   value={cari}
              //   onChange={value => {
              //     console.log("value cari >>>", value)
              //   }}
              //   className="form-control search-box me-2 mb-2 d-inline-block"
              //   placeholder={SearchPlaceholder}
              // />
              <Col sm={3}>
                <input
                  className="form-control search-box me-2 mb-2 d-inline-block flex-grow-1"
                  placeholder={SearchPlaceholder}
                  value={cariLokal}
                  onChange={e => setCariLokal(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      handleClickCari()
                    }
                  }}
                />
              </Col>
            )}
            <Col sm={3}>
              {isAddButton && (
                <div className="text-start flex-grow-1">
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    onClick={() => handleClickCari()}
                    // disabled={loadingAddElemen}
                  >
                    <i className="mdi mdi-search-web me-1"></i>
                    Cari
                  </button>
                  {/* <Button
                    type="button"
                    className={"btn btn-primary btn-block"}
                    onClick={() => {
                      dispatch(
                        getElemen({
                          tipe: tipe,
                          limit: limit,
                          cari: cariLokal,
                          page: page,
                        })
                      )
                    }}
                  >
                    <i className="mdi mdi-search-web me-1"></i> {buttonName}
                  </Button> */}
                </div>
              )}
            </Col>
          </Row>
          <div className={divClassName}>
            <Table hover className={tableClass} bordered={isBordered}>
              <thead className={theadClass}>
                {getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <Fragment>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {header.column.columnDef.header}
                            </div>
                          </Fragment>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {/* <tbody>
            {getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  //  console.log("cell >>", cell)
                  <td key={cell.id}>{cell.column.columnDef.cell}</td>
                  // <td key={cell.id}>{cell.row.original}</td>
                ))}
              </tr>
            ))}
          </tbody> */}
              {/* <tbody>
                {getRowModel().rows.map(row => {
                  // console.log("row >>", row)
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        // console.log("Cell value:", cell) // Log cell value
                        return <td key={cell.id}>{cell.renderValue()}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody> */}
              <tbody>
                {dataUsed.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>
                        {
                          // console.log("row >>>", rowIndex)
                          // column.cell
                          //   ? column.cell({ row, rowIndex })
                          //   : typeof column.accessorFn === "function"
                          //   ? column.accessorFn(row)
                          //   : row[column.accessorFn]
                          column.header === "No"
                            ? rowIndex + 1
                            : column.cell
                            ? column.cell({ row, rowIndex })
                            : typeof column.accessorFn === "function"
                            ? column.accessorFn(row)
                            : row[column.accessorFn]
                        }
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {isPagination && (
            <Row>
              <Col sm={12} md={5}>
                <div className="dataTables_info">
                  Menampilkan {getState().pagination.pageSize} dari{" "}
                  {elements.totalDocs} Data
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className={paginationWrapper}>
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        !getCanPreviousPage() ? "disabled" : ""
                      }`}
                    >
                      <Link to="#" className="page-link" onClick={previousPage}>
                        <i className="mdi mdi-chevron-left"></i>
                      </Link>
                    </li>
                    {getPageOptions().map((item, key) => (
                      <li
                        key={key}
                        className={`paginate_button page-item ${
                          getState().pagination.pageIndex === item
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => setPageIndex(item)}
                        >
                          {item + 1}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        !getCanNextPage() ? "disabled" : ""
                      }`}
                    >
                      <Link to="#" className="page-link" onClick={nextPage}>
                        <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </>
  )
}

export default CustomTable
