import {
  POST_TEMUAN,
  POST_TEMUAN_FAIL,
  POST_TEMUAN_SUCCESS,
  GET_TEMUAN,
  GET_TEMUAN_FAIL,
  GET_TEMUAN_SUCCESS,
} from "./actionTypes"

const initialState = {
  loadingGet: false,
  loadingAdd: false,
  temuan: null,
  errorGet: null,
  errorAdd: null,
  page: 0,
  limit: 10,
  cari: "",
  idRuangan: "",
}

const temuan = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMUAN:
      state = {
        ...state,
        loadingGet: true,
        page: action.payload.page,
        limit: action.payload.limit,
        cari: action.payload.cari,
        idRuangan: action.payload.idRuangan,
      }
      break
    case GET_TEMUAN_SUCCESS:
      state = {
        ...state,
        loadingGet: false,
        temuan: action.payload,
      }
      break
    case GET_TEMUAN_FAIL:
      state = {
        ...state,
        loadingGet: false,
        errorGet: action.payload,
      }
      break
    case POST_TEMUAN:
      state = {
        ...state,
        loadingAdd: true,
      }
      break
    case POST_TEMUAN_SUCCESS:
      state = {
        ...state,
        loadingAdd: false,
      }
      break
    case POST_TEMUAN_FAIL:
      state = {
        ...state,
        loadingAdd: false,
        errorAdd: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default temuan
