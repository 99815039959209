// import { er } from "@fullcalendar/core/internal-common"
import {
  ADD_TUGAS,
  ADD_TUGAS_FAIL,
  ADD_TUGAS_SUCCESS,
  GET_TUGAS,
  GET_TUGAS_FAIL,
  GET_TUGAS_SUCCESS,
  GET_ELEMENT_JSON,
  GET_ELEMENT_JSON_FAIL,
  GET_ELEMENT_JSON_SUCCESS,
  DELETE_TUGAS,
  DELETE_TUGAS_SUCCESS,
  DELETE_TUGAS_FAIL,
  ADD_NUM_LAPORAN,
  DELETE_NUM_LAPORAN,
  CLEAR_ID_TUGAS,
  DELETE_TUGAS_LAPORAN,
  DELETE_TUGAS_LAPORAN_SUCCESS,
  DELETE_TUGAS_LAPORAN_FAIL,
} from "./actionTypes"

const initialState = {
  loadingGetJson: false,
  loadingAdd: false,
  loadingGet: false,
  errorGetJson: null,
  errorAdd: null,
  errorGet: null,
  elemenJson: null,
  tugas: null,
  page: 0,
  limit: 10,
  cari: "",
  elemen: null,
  idTugasToDelete: null,
  loadingDelete: false,
  errorDelete: null,
  // rcDelete: null,
  numLaporanToDelete: 0,
}

const tugas = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TUGAS:
      state = {
        ...state,
        loadingAdd: true,
      }
      break
    case ADD_TUGAS_SUCCESS:
      state = {
        ...state,
        loadingAdd: false,
      }
      break
    case ADD_TUGAS_FAIL:
      state = {
        ...state,
        loadingAdd: false,
        errorAdd: action.payload,
      }
      break
    case GET_TUGAS:
      state = {
        ...state,
        tugas: null,
        loadingGet: true,
        page: action.payload.page,
        limit: action.payload.limit,
        cari: action.payload.cari,
      }
      break
    case GET_TUGAS_SUCCESS:
      state = {
        ...state,
        tugas: action.payload,
        loadingGet: false,
      }
      break
    case GET_TUGAS_FAIL:
      state = {
        ...state,
        loadingGet: false,
        errorGet: action.payload,
      }
      break
    case GET_ELEMENT_JSON:
      state = {
        ...state,
        loadingGetJson: true,
      }
      break
    case GET_ELEMENT_JSON_SUCCESS:
      state = {
        ...state,
        loadingGetJson: false,
        elemenJson: action.payload,
      }
      break
    case GET_ELEMENT_JSON_FAIL:
      state = {
        ...state,
        loadingGetJson: false,
        errorGetJson: action.payload,
      }
      break
    case DELETE_TUGAS:
      state = {
        ...state,
        idTugasToDelete: action.payload,
        loadingDelete: true,
      }
      break
    case DELETE_TUGAS_SUCCESS:
      state = {
        ...state,
        loadingDelete: false,
        // idTugasToDelete: null,
      }
      break
    case CLEAR_ID_TUGAS:
      state = {
        ...state,
        idTugasToDelete: null,
      }
      break
    case DELETE_TUGAS_FAIL:
      state = {
        ...state,
        loadingDelete: false,
        idTugasToDelete: null,
        errorDelete: action.payload,
      }
      break
    case ADD_NUM_LAPORAN:
      state = {
        ...state,
        numLaporanToDelete: action.payload,
      }
      break
    case DELETE_NUM_LAPORAN:
      state = {
        ...state,
        numLaporanToDelete: 0,
      }
      break
    case DELETE_TUGAS_LAPORAN:
      state = {
        ...state,
        loadingDelete: true,
      }
      break
    case DELETE_TUGAS_LAPORAN_SUCCESS:
      state = {
        ...state,
        loadingDelete: false,
      }
      break
    case DELETE_TUGAS_LAPORAN_FAIL:
      state = {
        ...state,
        loadingDelete: false,
        errorDelete: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default tugas
