import {
  ADD_ELEMENT,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  GET_ELEMENT,
  GET_ELEMENT_SUCCESS,
  GET_ELEMENT_FAIL,
  CHANGE_CARI,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  CHANGE_CARI_SUCCSESS,
  CHANGE_LIMIT_SUCCSESS,
  CHANGE_PAGE_SUCCSESS,
} from "./actionTypes"

export const addElemen = data => ({
  type: ADD_ELEMENT,
  payload: data,
})

export const addElemenSuccess = data => ({
  type: ADD_ELEMENT_SUCCESS,
  payload: data,
})

export const addElemenFail = data => ({
  type: ADD_ELEMENT_FAIL,
  payload: data,
})

export const getElemen = data => ({
  type: GET_ELEMENT,
  payload: data,
})

export const getElemenSuccess = data => ({
  type: GET_ELEMENT_SUCCESS,
  payload: data,
})

export const getElemenFail = data => ({
  type: GET_ELEMENT_FAIL,
  payload: data,
})

export const changeCari = data => ({
  type: CHANGE_CARI,
  payload: data,
})

export const changeLimit = data => ({
  type: CHANGE_LIMIT,
  payload: data,
})

export const changePage = data => ({
  type: CHANGE_PAGE,
  payload: data,
})

export const changeCariSuccsess = data => ({
  type: CHANGE_CARI_SUCCSESS,
})

export const changeLimitSuccsess = data => ({
  type: CHANGE_LIMIT_SUCCSESS,
})

export const changePageSuccsess = data => ({
  type: CHANGE_PAGE_SUCCSESS,
})
