export const ADD_ELEMENT = "ADD_ELEMENT"
export const ADD_ELEMENT_SUCCESS = "ADD_ELEMENT_SUCCESS"
export const ADD_ELEMENT_FAIL = "ERROR_ADD_ELEMENT"
export const GET_ELEMENT = "GET_ELEMNT"
export const GET_ELEMENT_SUCCESS = "GET_ELEMENT_SUCCESS"
export const GET_ELEMENT_FAIL = "GET_ELEMENT_FAIL"
export const CHANGE_PAGE = "CHANGE_PAGE"
export const CHANGE_LIMIT = "CHANGE_LIMIT"
export const CHANGE_CARI = "CHANGE_CARI"
export const CHANGE_PAGE_SUCCSESS = "CHANGE_PAGE_SUCCSESS"
export const CHANGE_LIMIT_SUCCSESS = "CHANGE_LIMIT_SUCCSESS"
export const CHANGE_CARI_SUCCSESS = "CHANGE_CARI_SUCCSESS"
