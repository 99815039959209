export const GET_TEMUAN = "GET_TEMUAN"
export const GET_TEMUAN_SUCCESS = "GET_TEMUAN_SUCCESS"
export const GET_TEMUAN_FAIL = "GET_TEMUAN_FAIL"
export const GET_RUANGAN = "GET_RUANGAN"
export const GET_RUANGAN_SUCCESS = "GET_RUANGAN_SUCCESS"
export const GET_RUANGAN_FAIL = "GET_RUANGAN_FAIL"
export const CLEAR_TEMUAN = "CLEAR_TEMUAN"
export const POST_VALIDASI = "POST_VALIDASI"
export const POST_VALIDASI_SUCCESS = "POST_VALIDASI_SUCCESS"
export const POST_VALIDASI_FAIL = "POST_VALIDASI_FAIL"
export const POST_SELESAI = "POST_SELESAI"
export const POST_SELESAI_SUCCESS = "POST_SELESAI_SUCCESS"
export const POST_SELESAI_FAIL = "POST_SELESAI_FAIL"
