import {
  GET_LAPORAN,
  GET_LAPORAN_FAIL,
  GET_LAPORAN_SUCCESS,
} from "./actionTypes"
import moment from "moment"

const initialState = {
  loading: false,
  error: null,
  laporan: null,
  page: 0,
  limit: 10,
  cari: "",
  periode: "",
  tglAwal: moment(new Date()).format("YYYY-MM-DD"),
  tglAkhir: moment(new Date()).format("YYYY-MM-DD"),
}

const laporan = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAPORAN:
      state = {
        ...state,
        loading: true,
        page: action.payload.page,
        limit: action.payload.limit,
        cari: action.payload.cari,
        periode: action.payload.periode,
        tglAwal: action.payload.tglAwal,
        tglAkhir: action.payload.tglAkhir,
      }
      break
    case GET_LAPORAN_SUCCESS:
      state = {
        ...state,
        loading: false,
        laporan: action.payload,
      }
      break
    case GET_LAPORAN_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default laporan
