import {
  GET_TEMUAN,
  GET_TEMUAN_SUCCESS,
  GET_TEMUAN_FAIL,
  GET_RUANGAN,
  GET_RUANGAN_SUCCESS,
  GET_RUANGAN_FAIL,
  POST_VALIDASI,
  POST_VALIDASI_SUCCESS,
  POST_VALIDASI_FAIL,
  POST_SELESAI,
  POST_SELESAI_SUCCESS,
  POST_SELESAI_FAIL,
} from "./actionTypes"
import { call, put, takeEvery, select } from "redux-saga/effects"
import {
  getRuanganTemuanAdmin,
  getTemuanAdmin,
  postValidasiBe,
  postSelesaiBe,
} from "helpers/fakebackend_helper"
import {
  getRuanganAdminSuccess,
  getRuanganAdminFail,
  getTemuanAdminSuccess,
  getTemuanAdminFail,
  postValidasiSuccess,
  postValidasiFail,
  postSelesaiSuccess,
  postSelesaiFail,
} from "./actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const page = state => state.temuanAdmin.page
const limit = state => state.temuanAdmin.limit
const cari = state => state.temuanAdmin.cari
const idRuangan = state => state.temuanAdmin.idRuangan

function* getRuanganTemuanAdminSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>>", action)
    const response = yield call(getRuanganTemuanAdmin, action)
    // console.log("response di saga >>>>", response)
    yield put(getRuanganAdminSuccess(response))
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(getRuanganAdminFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan data ", { autoClose: 2000 })
    }
  }
}

function* getTemuanByIdRuanganSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(getTemuanAdmin, action)
    // console.log("response di saga >>>>", response)
    yield put(getTemuanAdminSuccess(response))
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(getTemuanAdminFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan tugas ", { autoClose: 2000 })
    }
  }
}

function* postValidasiSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(postValidasiBe, action)
    // console.log("response di saga >>>>", response)
    toast.success("Berhasil validasi temuan", { autoClose: 2000 })
    yield put(postValidasiSuccess(response))
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)
    const idRuanganState = yield select(idRuangan)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
      idRuangan: idRuanganState,
    }

    yield call(getTemuanByIdRuanganSaga, { payload: payloadGet })
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(postValidasiSuccess(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal melakukan validasi ", { autoClose: 2000 })
    }
  }
}

function* postSelesaiSaga({ payload: action }) {
  // console.log("action di saga >>>")
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(postSelesaiBe, action)
    // console.log("response di saga >>>>", response)
    toast.success("Berhasil menyelesaikan temuan", { autoClose: 2000 })
    yield put(postSelesaiSuccess(response))
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)
    const idRuanganState = yield select(idRuangan)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
      idRuangan: idRuanganState,
    }

    yield call(getTemuanByIdRuanganSaga, { payload: payloadGet })
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(postSelesaiBe(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menyelesaikan temuan ", { autoClose: 2000 })
    }
  }
}

function* getTemuanAdminSaga() {
  yield takeEvery(GET_RUANGAN, getRuanganTemuanAdminSaga)
  yield takeEvery(GET_TEMUAN, getTemuanByIdRuanganSaga)
  yield takeEvery(POST_VALIDASI, postValidasiSaga)
  yield takeEvery(POST_SELESAI, postSelesaiSaga)
}

export default getTemuanAdminSaga
