import React, { Fragment, useEffect, useState } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { Link } from "react-router-dom"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table"
// import JobListGlobalFilter from "./GlobalSearchFilter" // If you have a custom global filter component
import {
  changeCari,
  changeCariSuccsess,
  changeLimit,
  changeLimitSuccsess,
  changePage,
  changePageSuccsess,
  getElemen,
  getTugas,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const DebouncedInput = ({
  value: initialValue,
  onChange,
  // debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // useEffect(() => {
  //   // const timeout = setTimeout(() => {
  //   //   onChange(value)
  //   // }, debounce)
  //   return () => clearTimeout(timeout)
  // }, [onChange, value])

  return (
    <Col sm={4}>
      <input
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </Col>
  )
}

const DataTable = ({
  columns,
  apiUrl,
  // dataParsed,
  tipe,
  tableClass = "table-class",
  theadClass = "thead-class",
  divClassName = "table-responsive",
  isBordered = true,
  isPagination = true,
  isGlobalFilter = false,
  paginationWrapper,
  SearchPlaceholder = "Search...",
  buttonClass = "btn-primary",
  buttonName = "Add New",
  isAddButton = true,
  isCustomPageSize = true,
  handleUserClick,
  isJobListGlobalFilter = false,
}) => {
  const dispatch = useDispatch()
  const tugas = useSelector(state => state.tugas.tugas)
  const page = useSelector(state => state.tugas.page)
  const limit = useSelector(state => state.tugas.limit)
  const cari = useSelector(state => state.tugas.cari)
  const loadingGetTugas = useSelector(state => state.tugas.loadingGet)
  const dataUsed = tugas.data
  const [cariLokal, setCariLokal] = useState(cari)

  const table = useReactTable({
    columns,
    data: dataUsed,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(tugas.totalDocs / limit),
    manualGlobalFilter: true,
    state: {
      globalFilter: cari,
      pagination: {
        pageIndex: page,
        pageSize: limit,
      },
    },
    onPaginationChange: state => {
      let payload = {
        limit: limit,
        cari: cari,
        page: state(table.getState().pagination).pageIndex,
      }
      dispatch(getTugas(payload))
    },
    onGlobalFilterChange: value => {
      console.log("value cari >>>", value)
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    getState,
  } = table

  const generatePagination = () => {
    const { pageIndex } = getState().pagination
    const totalPages = Math.ceil(tugas.totalDocs / limit)
    const paginationRange = []
    const maxVisibleButtons = 5

    let startPage = Math.max(
      1,
      pageIndex + 1 - Math.floor(maxVisibleButtons / 2)
    )
    let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1)

    if (endPage - startPage < maxVisibleButtons - 1) {
      startPage = Math.max(1, endPage - maxVisibleButtons + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationRange.push(i)
    }

    return (
      <>
        <li
          className={`paginate_button page-item previous ${
            !getCanPreviousPage() ? "disabled" : ""
          }`}
        >
          <Link to="#" className="page-link" onClick={previousPage}>
            <i className="mdi mdi-chevron-left"></i>
          </Link>
        </li>
        {startPage > 1 && (
          <li className="paginate_button page-item">
            <Link to="#" className="page-link" onClick={() => setPageIndex(0)}>
              1
            </Link>
          </li>
        )}
        {startPage > 2 && (
          <li className="paginate_button page-item disabled">...</li>
        )}
        {paginationRange.map(item => (
          <li
            key={item}
            className={`paginate_button page-item ${
              pageIndex + 1 === item ? "active" : ""
            }`}
          >
            <Link
              to="#"
              className="page-link"
              onClick={() => setPageIndex(item - 1)}
            >
              {item}
            </Link>
          </li>
        ))}
        {endPage < totalPages - 1 && (
          <li className="paginate_button page-item disabled">...</li>
        )}
        {endPage < totalPages && (
          <li className="paginate_button page-item">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPageIndex(totalPages - 1)}
            >
              {totalPages}
            </Link>
          </li>
        )}
        <li
          className={`paginate_button page-item next ${
            !getCanNextPage() ? "disabled" : ""
          }`}
        >
          <Link to="#" className="page-link" onClick={nextPage}>
            <i className="mdi mdi-chevron-right"></i>
          </Link>
        </li>
      </>
    )
  }

  return (
    <>
      {loadingGetTugas ? null : (
        <Fragment>
          <Row className="mb-2">
            {isCustomPageSize && (
              <Col sm={3}>
                <select
                  className="form-select pageSize mb-2 flex-grow-1"
                  value={limit}
                  onChange={e =>
                    // console.log(
                    //   "value >>>",
                    //   e.target.value,
                    //   typeof e.target.value
                    // )
                    // setLimit(Number(e.target.value))
                    dispatch(
                      getTugas({
                        limit: parseInt(e.target.value),
                        cari: cari,
                        page: page,
                      })
                    )
                  }
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Tampilkan {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              // <DebouncedInput
              //   value={cari}
              //   onChange={value => {
              //     console.log("value cari >>>", value)
              //   }}
              //   className="form-control search-box me-2 mb-2 d-inline-block"
              //   placeholder={SearchPlaceholder}
              // />
              <Col sm={3}>
                <input
                  className="form-control search-box me-2 mb-2 d-inline-block flex-grow-1"
                  placeholder={SearchPlaceholder}
                  value={cariLokal}
                  // onChange={e => setValue(e.target.value)}
                  onChange={
                    e => setCariLokal(e.target.value)
                    // dispatch(
                    //   getElemen({
                    //     tipe: tipe,
                    //     limit: limit,
                    //     cari: e.target.value,
                    //     page: page,
                    //   })
                    // )
                  }
                />
              </Col>
            )}
            {/* {isJobListGlobalFilter && (
              <JobListGlobalFilter setGlobalFilter={console.log("asd")} />
            )} */}
            {isAddButton && (
              <Col sm={2}>
                <div className="text-sm-end flex-grow-1">
                  <Button
                    type="button"
                    className={buttonClass}
                    onClick={() => {
                      dispatch(
                        getTugas({
                          limit: limit,
                          cari: cariLokal,
                          page: page,
                        })
                      )
                    }}
                  >
                    <i className="mdi mdi-plus me-1"></i> {buttonName}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <div className={divClassName}>
            <Table hover className={tableClass} bordered={isBordered}>
              <thead className={theadClass}>
                {getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <Fragment>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {header.column.columnDef.header}
                            </div>
                          </Fragment>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {/* <tbody>
            {getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  //  console.log("cell >>", cell)
                  <td key={cell.id}>{cell.column.columnDef.cell}</td>
                  // <td key={cell.id}>{cell.row.original}</td>
                ))}
              </tr>
            ))}
          </tbody> */}
              {/* <tbody>
                {getRowModel().rows.map(row => {
                  // console.log("row >>", row)
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        // console.log("Cell value:", cell) // Log cell value
                        return <td key={cell.id}>{cell.renderValue()}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody> */}
              <tbody>
                {dataUsed.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>
                        {
                          // console.log("row >>>", rowIndex)
                          // column.cell
                          //   ? column.cell({ row, rowIndex })
                          //   : typeof column.accessorFn === "function"
                          //   ? column.accessorFn(row)
                          //   : row[column.accessorFn]
                          column.header === "No"
                            ? rowIndex + 1
                            : column.cell
                            ? column.cell({ row, rowIndex })
                            : typeof column.accessorFn === "function"
                            ? column.accessorFn(row)
                            : row[column.accessorFn]
                        }
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {isPagination && (
            <Row>
              <Col sm={12} md={5}>
                <div className="dataTables_info">
                  Menampilkan {getState().pagination.pageSize} dari{" "}
                  {tugas.totalDocs} Data
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className={paginationWrapper}>
                  <ul className="pagination">
                    {/* <li
                      className={`paginate_button page-item previous ${
                        !getCanPreviousPage() ? "disabled" : ""
                      }`}
                    >
                      <Link to="#" className="page-link" onClick={previousPage}>
                        <i className="mdi mdi-chevron-left"></i>
                      </Link>
                    </li>
                    {getPageOptions().map((item, key) => (
                      <li
                        key={key}
                        className={`paginate_button page-item ${
                          getState().pagination.pageIndex === item
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => setPageIndex(item)}
                        >
                          {item + 1}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        !getCanNextPage() ? "disabled" : ""
                      }`}
                    >
                      <Link to="#" className="page-link" onClick={nextPage}>
                        <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </li> */}
                    {generatePagination()}
                  </ul>
                </div>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </>
  )
}

export default DataTable
