import {
  POST_TEMUAN,
  POST_TEMUAN_FAIL,
  POST_TEMUAN_SUCCESS,
  GET_TEMUAN,
  GET_TEMUAN_FAIL,
  GET_TEMUAN_SUCCESS,
} from "./actionTypes"
import { call, put, takeEvery, select } from "redux-saga/effects"
import { getTemuan, postAddTemuan } from "../../helpers/fakebackend_helper"
import {
  getTemuanSuccess,
  getTemuanFail,
  postTemuanSuccess,
  postTemuanFail,
} from "./actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const page = state => state.temuan.page
const limit = state => state.temuan.limit
const cari = state => state.temuan.cari
const idRuangan = state => state.temuan.idRuangan

function* getTemuanSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(getTemuan, action)
    // console.log("response di saga >>>>", response)
    yield put(getTemuanSuccess(response))
  } catch (error) {
    // console.log("error di saga >>>>", error)
    yield put(getTemuanFail(error))
  }
}

function* postTemuanSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(postAddTemuan, action)
    // console.log("response di saga >>>>", response)
    yield put(postTemuanSuccess(response))
    toast.success("Berhasil menambahkan temuan", { autoClose: 2000 })
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)
    const idRuanganState = yield select(idRuangan)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
      idRuangan: idRuanganState,
    }
    yield call(getTemuanSaga, { payload: payloadGet })
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(addTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menambahkan tugas ", { autoClose: 2000 })
    }
  }
}

function* temuanSaga() {
  yield takeEvery(GET_TEMUAN, getTemuanSaga)
  yield takeEvery(POST_TEMUAN, postTemuanSaga)
}

export default temuanSaga
