import {
  ADD_ELEMENT,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  GET_ELEMENT,
  GET_ELEMENT_FAIL,
  GET_ELEMENT_SUCCESS,
  CHANGE_CARI,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  CHANGE_CARI_SUCCSESS,
  CHANGE_LIMIT_SUCCSESS,
  CHANGE_PAGE_SUCCSESS,
} from "./actionTypes"
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects"
import { postAddElement, getElement } from "../../helpers/fakebackend_helper"
import {
  // addElemen,
  addElemenSuccess,
  addElemenFail,
  getElemen,
  getElemenSuccess,
  getElemenFail,
} from "./actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useDispatch, useSelector } from "react-redux"
const page = state => state.elemen.page
const limit = state => state.elemen.limit
const cari = state => state.elemen.cari

function* addElement({ payload: action }) {
  try {
    const response = yield call(postAddElement, action)
    // console.log("action di saga >>>", action)
    // console.log("response di saga >>>>", response)
    yield put(addElemenSuccess(response))
    toast.success("Berhasil tambah " + action.elemen, { autoClose: 2000 })
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)

    const payloadGet = {
      tipe: action.elemen,
      limit: limitState,
      page: pageState,
      cari: cariState,
    }
    yield call(getElemenSaga, { payload: payloadGet })
    // yield console.log("action di saga ???", { payload: action })
  } catch (error) {
    // yield console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(addElemenFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menambahkan " + action.elemen, { autoClose: 2000 })
    }
    // yield put(addElemenFail(error))
  }
}

function* getElemenSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(getElement, action)
    // console.log("response di saga >>>>", response)
    yield put(getElemenSuccess(response))
  } catch (error) {
    toast.error("gagal mendapatkan data", { autoClose: 2000 })
    // console.log("error di saga >>>>", error)
    // yield put(getElemenFail(error))
  }
}

function* elemetSaga() {
  yield takeEvery(ADD_ELEMENT, addElement)
  yield takeEvery(GET_ELEMENT, getElemenSaga)
  yield takeEvery(CHANGE_CARI, getElemenSaga)
  yield takeEvery(CHANGE_LIMIT, getElemenSaga)
  yield takeEvery(CHANGE_PAGE, getElemenSaga)
}

export default elemetSaga
