import { LOGIN_BAP, ERROR_API, LOGIN_BAP_SUCCESS, REFRESH_TOKEN_ERROR } from "./actionTypes"

const initialState = {
  crendential: {},
  error: {},
  erorRefresh: {},
  loading: false,
}

const loginBap = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_BAP:
      state = {
        ...state,
        loading: true,
      }
      break
    case ERROR_API:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case LOGIN_BAP_SUCCESS:
      state = {
        ...state,
        loading: false,
        crendential: action.payload,
        error: {},
        erorRefresh: {}
      }
      break
    case REFRESH_TOKEN_ERROR:
      state = {
        ...state,
        erorRefresh: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default loginBap
