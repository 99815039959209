import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  ModalFooter,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { getLaporan } from "store/actions"
import { ToastContainer } from "react-toastify"
import DataTable from "./dataTable"
import classnames from "classnames"
import moment from "moment"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

const Laporan = () => {
  document.title = "BAP"
  const dispatch = useDispatch()
  const laporan = useSelector(state => state.laporan.laporan)
  const loading = useSelector(state => state.laporan.loading)
  const page = useSelector(state => state.laporan.page)
  const limit = useSelector(state => state.laporan.limit)
  const cari = useSelector(state => state.laporan.cari)
  const tglAwal = useSelector(state => state.laporan.tglAwal)
  const tglAkhir = useSelector(state => state.laporan.tglAkhir)
  const periode = useSelector(state => state.laporan.periode)
  const [modal, setModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState("")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [dataDetailLaporan, setDataDetailLaporan] = useState(null)
  const [locLat, setLocLat] = useState(0)
  const [locLong, setLocLong] = useState(0)
  const toggleModal = () => setModal(!modal)

  const formatDate = dateString => {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date)
  }

  const column = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "id Tugas",
      accessorFn: row => row.idTugas,
    },
    {
      header: "Tugas",
      accessorFn: row =>
        row.area.namaArea +
        " - " +
        row.ruangan.namaRuangan +
        " - " +
        row.pekerjaan.namaPekerjaan +
        " - " +
        row.barang.namaBarang,
    },
    {
      header: "Target",
      accessorFn: row => row.totalTarget,
    },
    {
      header: "Laporan",
      accessorFn: row => row.jumlahLaporan,
    },
    {
      header: "Keterangan",
      accessorFn: row => row.achieve,
    },
    {
      header: "Detail",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              setDataDetailLaporan(row.laporan)
              // console.log(row.laporan)
            }}
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
          >
            Lihat
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(
      getLaporan({
        limit: limit,
        cari: cari,
        page: page,
        periode:
          verticalActiveTab === "1"
            ? "Harian"
            : verticalActiveTab === "2"
            ? "Mingguan"
            : verticalActiveTab === "3"
            ? "Bulanan"
            : "Harian",
        tglAwal: tglAwal,
        tglAkhir: tglAkhir,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kebersihan" breadcrumbItem="Laporan" />
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {dataDetailLaporan !== null ? (
                        <div className="flex-grow-1">
                          <div className="d-flex align-items-start">
                            <button
                              className="btn p-0"
                              onClick={() => setDataDetailLaporan(null)}
                              style={{ background: "none", border: "none" }} // No button styling, just the icon
                            >
                              <i
                                className="mdi mdi-keyboard-backspace"
                                style={{ fontSize: "24px" }}
                              />
                            </button>
                            <div className="ms-3">
                              <CardTitle className="h4">Laporan</CardTitle>
                              <CardSubtitle className="card-title-desc">
                                Daftar detail laporan
                              </CardSubtitle>
                            </div>
                          </div>
                          <Row className="text-center justify-content-center align-items-center">
                            <Table className="table table-striped table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>id Laporan</th>
                                  <th>Tanggal</th>
                                  <th>Hasil</th>
                                  <th>Foto & lokasi</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataDetailLaporan.map((item, index) => {
                                  const humanReadableDate = moment(
                                    item.dateTimeLaporan
                                  ).format("DD-MMM-YYYY, HH:mm:ss ")

                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.idLaporan}</td>
                                      <td>{humanReadableDate}</td>
                                      <td>{item.hasil}</td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            toggleModal()
                                            setSelectedImage(item.foto_laporan)
                                            setLocLat(parseFloat(item.lat))
                                            setLocLong(parseFloat(item.long))
                                          }}
                                          type="button"
                                          color="primary"
                                          className="btn-sm btn-rounded"
                                        >
                                          Lihat
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </Row>
                        </div>
                      ) : (
                        <div className="flex-grow-1">
                          <CardTitle className="h4">Daftar laporan</CardTitle>
                          <CardSubtitle className="card-title-desc">
                            Daftar laporan oleh petugas kebersihan
                          </CardSubtitle>
                          <Row className="justify-content-center ">
                            <Col md="3">
                              <Nav pills className="flex-column">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalActiveTab === "1",
                                    })}
                                    onClick={() => {
                                      setverticalActiveTab("1")
                                      let today = moment(new Date()).format(
                                        "YYYY-MM-DD"
                                      )
                                      // console.log("today >>>", today)
                                      dispatch(
                                        getLaporan({
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          periode: "Harian",
                                          tglAwal: today,
                                          tglAkhir: today,
                                        })
                                      )
                                    }}
                                  >
                                    Harian
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalActiveTab === "2",
                                    })}
                                    onClick={() => {
                                      setverticalActiveTab("2")
                                      let startOfWeek = moment(new Date())
                                        .startOf("week")
                                        .add(1, "days")
                                        .format("YYYY-MM-DD")
                                      let endOfWeek = moment(new Date())
                                        .endOf("week")
                                        .add(1, "days")
                                        .format("YYYY-MM-DD")
                                      // console.log("startOfWeek >>>", startOfWeek)
                                      // console.log("endOfWeek >>>", endOfWeek)
                                      dispatch(
                                        getLaporan({
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          periode: "Mingguan",
                                          tglAwal: startOfWeek,
                                          tglAkhir: endOfWeek,
                                        })
                                      )
                                    }}
                                  >
                                    Mingguan
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalActiveTab === "3",
                                    })}
                                    onClick={() => {
                                      setverticalActiveTab("3")
                                      let startOfMonth = moment(new Date())
                                        .startOf("month")
                                        .format("YYYY-MM-DD")
                                      let endOfMonth = moment(new Date())
                                        .endOf("month")
                                        .format("YYYY-MM-DD")
                                      // console.log("startOfWeek >>>", startOfMonth)
                                      // console.log("endOfWeek >>>", endOfMonth)
                                      dispatch(
                                        getLaporan({
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          limit: limit,
                                          cari: cari,
                                          page: page,
                                          periode: "Bulanan",
                                          tglAwal: startOfMonth,
                                          tglAkhir: endOfMonth,
                                        })
                                      )
                                    }}
                                  >
                                    Bulanan
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md="9">
                              {loading || laporan === null ? (
                                <Spinner size="lg" color="primary" />
                              ) : (
                                <DataTable
                                  columns={column}
                                  tableClass="table-striped"
                                  theadClass="thead-dark"
                                  divClassName="table-responsive-sm"
                                  isBordered={true}
                                  isPagination={true}
                                  isGlobalFilter={true}
                                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                  SearchPlaceholder="Cari id tugas ..."
                                  buttonClass="btn-success"
                                  buttonName="Cari"
                                  isAddButton={true}
                                  isCustomPageSize={true}
                                  handleUserClick={() => console.log("yuhu")}
                                  isJobListGlobalFilter={false}
                                  tab={verticalActiveTab}
                                />
                              )}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Foto & lokasi</ModalHeader>
            <ModalBody>
              <Row className="justify-content-center align-items-center my-3">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Detail"
                    className="img-fluid"
                    style={{ width: "75%", height: "50%" }}
                  />
                ) : (
                  "No Image Available"
                )}
              </Row>
              <Row className="justify-content-center align-items-center my-3">
                <iframe
                  title="Google Map"
                  width="100%"
                  height="400px"
                  src={`https://maps.google.com/maps?q=${locLat},${locLong}&hl=es;z=14&output=embed`}
                  allowFullScreen
                ></iframe>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAXcaz5mPbGbZs4rrFVHLTANG0NLoBEp2I",
  // LoadingContainer: LoadingContainer,
  v: "3",
})(Laporan)
