import {
  ADD_TUGAS,
  ADD_TUGAS_FAIL,
  ADD_TUGAS_SUCCESS,
  GET_TUGAS,
  GET_TUGAS_FAIL,
  GET_TUGAS_SUCCESS,
  GET_ELEMENT_JSON,
  GET_ELEMENT_JSON_FAIL,
  GET_ELEMENT_JSON_SUCCESS,
  DELETE_TUGAS,
  DELETE_TUGAS_FAIL,
  DELETE_TUGAS_SUCCESS,
  ADD_NUM_LAPORAN,
  DELETE_NUM_LAPORAN,
  DELETE_TUGAS_LAPORAN,
  CLEAR_ID_TUGAS,
  DELETE_TUGAS_LAPORAN_FAIL,
  DELETE_TUGAS_LAPORAN_SUCCESS,
} from "./actionTypes"

export const addTugas = data => {
  return {
    type: ADD_TUGAS,
    payload: data,
  }
}

export const addTugasSuccess = () => {
  return {
    type: ADD_TUGAS_SUCCESS,
  }
}

export const addTugasFail = data => {
  return {
    type: ADD_TUGAS_FAIL,
    payload: data,
  }
}

export const getTugas = data => {
  return {
    type: GET_TUGAS,
    payload: data,
  }
}

export const getTugasSuccess = data => {
  // console.log("============= data di action lemparan dari saga ???", data)
  return {
    type: GET_TUGAS_SUCCESS,
    payload: data,
  }
}

export const getTugasFail = data => {
  return {
    type: GET_TUGAS_FAIL,
    payload: data,
  }
}

export const getElementJson = () => {
  return {
    type: GET_ELEMENT_JSON,
  }
}

export const getElementJsonSuccess = data => {
  return {
    type: GET_ELEMENT_JSON_SUCCESS,
    payload: data,
  }
}

export const getElementJsonFail = data => {
  return {
    type: GET_ELEMENT_JSON_FAIL,
    payload: data,
  }
}

export const deleteTugas = data => {
  return {
    type: DELETE_TUGAS,
    payload: data,
  }
}

export const deleteTugasSuccess = () => {
  return {
    type: DELETE_TUGAS_SUCCESS,
  }
}

export const deleteTugasFail = data => {
  return {
    type: DELETE_TUGAS_FAIL,
    payload: data,
  }
}

export const addNumLaporan = data => {
  return {
    type: ADD_NUM_LAPORAN,
    payload: data,
  }
}

export const deleteNumLaporan = () => {
  return {
    type: DELETE_NUM_LAPORAN,
  }
}

export const deleteTugasLaporan = data => {
  return {
    type: DELETE_TUGAS_LAPORAN,
    payload: data,
  }
}

export const clearIdTugas = () => {
  return {
    type: CLEAR_ID_TUGAS,
  }
}

export const deleteTugasLaporanSuccess = () => {
  return {
    type: DELETE_TUGAS_LAPORAN_SUCCESS,
  }
}

export const deleteTugasLaporanFail = data => {
  return {
    type: DELETE_TUGAS_LAPORAN_FAIL,
    payload: data,
  }
}
