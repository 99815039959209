export const ADD_TUGAS = "ADD_TUGAS"
export const ADD_TUGAS_SUCCESS = "ADD_TUGAS_SUCCESS"
export const ADD_TUGAS_FAIL = "ADD_TUGAS_FAIL"
export const GET_TUGAS = "GET_TUGAS"
export const GET_TUGAS_SUCCESS = "GET_TUGAS_SUCCESS"
export const GET_TUGAS_FAIL = "GET_TUGAS_FAIL"
export const GET_ELEMENT_JSON = "GET_ELEMENT_JSON"
export const GET_ELEMENT_JSON_SUCCESS = "GET_ELEMENT_JSON_SUCCESS"
export const GET_ELEMENT_JSON_FAIL = "GET_ELEMENT_JSON_FAIL"
export const DELETE_TUGAS = "DELETE_TUGAS"
export const DELETE_TUGAS_SUCCESS = "DELETE_TUGAS_SUCCESS"
export const DELETE_TUGAS_FAIL = "DELETE_TUGAS_FAIL"
export const ADD_NUM_LAPORAN = "ADD_NUM_LAPORAN"
export const DELETE_NUM_LAPORAN = "DELETE_NUM_LAPORAN"
export const CLEAR_ID_TUGAS = "CLEAR_ID_TUGAS"
export const DELETE_TUGAS_LAPORAN = "DELETE_TUGAS_LAPORAN"
export const DELETE_TUGAS_LAPORAN_SUCCESS = "DELETE_TUGAS_LAPORAN_SUCCESS"
export const DELETE_TUGAS_LAPORAN_FAIL = "DELETE_TUGAS_LAPORAN_FAIL"
