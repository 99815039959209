import {
  GET_LAPORAN,
  GET_LAPORAN_FAIL,
  GET_LAPORAN_SUCCESS,
} from "./actionTypes"
import { call, put, takeEvery, select } from "redux-saga/effects"
import { getLaporan } from "../../helpers/fakebackend_helper"
import { getLaporanSuccess, getLaporanFail } from "./actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const page = state => state.tugas.page
const limit = state => state.tugas.limit
const cari = state => state.tugas.cari

function* getLaporanSaga({ payload: action }) {
  try {
    // console.log("action di saga >>>", action)
    const response = yield call(getLaporan, action)
    // console.log("response di saga >>>>", response)
    yield put(getLaporanSuccess(response))
  } catch (error) {
    // console.log("error di saga >>>>", error)
    if (error.response) {
      yield put(getLaporanFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan tugas ", { autoClose: 2000 })
    }
  }
}

function* laporanSaga() {
  yield takeEvery(GET_LAPORAN, getLaporanSaga)
}

export default laporanSaga
