import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { useSelector } from "react-redux"

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ================== REAL !!!!!!!!! =====================
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// const postLogin = async data => {
//   await post(url.POST_LOGIN, data)
// }

// local rafi
// const API_URL = "https://fxv09sx5-9030.asse.devtunnels.ms"

// atas
const API_URL = "https://apisimba.ptbap.net"

// local dewe
// const API_URL = "https://7jglnrfd-9030.asse.devtunnels.ms"

const postLogin = async data => {
  const response = await axios({
    url: API_URL + "/webLogin/loginUser",
    method: "post",
    data: data,
  })
  return response.data
}

const postRefreshWeb = async data => {
  const response = await axios({
    url: API_URL + "/webLogin/refreshUser",
    method: "get",
    headers: { token: data },
  })
  return response.data
}

const postAddElement = async data => {
  const token = localStorage.getItem("authUser")
  if (data.elemen === "area") {
    const response = await axios({
      url: API_URL + "/webArea/tambahArea",
      method: "post",
      headers: { token: token },
      data: { namaArea: data.nama },
    })
    return response.data
  } else if (data.elemen === "ruang") {
    const response = await axios({
      url: API_URL + "/webRuangan/tambahRuangan",
      method: "post",
      headers: { token: token },
      data: { namaRuangan: data.nama },
    })
    return response.data
  } else if (data.elemen === "pekerjaan") {
    const response = await axios({
      url: API_URL + "/webPekerjaan/tambahPekerjaan",
      method: "post",
      headers: { token: token },
      data: { namaPekerjaan: data.nama },
    })
    return response.data
  } else if (data.elemen === "barang") {
    const response = await axios({
      url: API_URL + "/webBarang/tambahBarang",
      method: "post",
      headers: { token: token },
      data: { namaBarang: data.nama },
    })
    return response.data
  } else if (data.elemen === "target") {
    const response = await axios({
      url: API_URL + "/webTarget/tambahTarget",
      method: "post",
      headers: { token: token },
      data: { target: data.nama },
    })
    return response.data
  } else if (data.elemen === "parameter") {
    const response = await axios({
      url: API_URL + "/webParam/tambahParameter",
      method: "post",
      headers: { token: token },
      data: { parameter: data.nama },
    })
    return response.data
  }
}

const getElement = async data => {
  const token = localStorage.getItem("authUser")
  if (data.tipe === "area") {
    const response = await axios({
      url:
        API_URL +
        `/webArea/getAllArea?page=${data.page + 1}&limit=${data.limit}&cari=${
          data.cari
        }`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  } else if (data.tipe === "ruang") {
    const response = await axios({
      url:
        API_URL +
        `/webRuangan/getAllRuangan?page=${data.page + 1}&limit=${
          data.limit
        }&cari=${data.cari}`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  } else if (data.tipe === "pekerjaan") {
    const response = await axios({
      url:
        API_URL +
        `/webPekerjaan/getAllPekerjaan?page=${data.page + 1}&limit=${
          data.limit
        }&cari=${data.cari}`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  } else if (data.tipe === "barang") {
    const response = await axios({
      url:
        API_URL +
        `/webBarang/getAllBarang?page=${data.page + 1}&limit=${
          data.limit
        }&cari=${data.cari}`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  } else if (data.tipe === "target") {
    const response = await axios({
      url:
        API_URL +
        `/webTarget/getAllTarget?page=${data.page + 1}&limit=${
          data.limit
        }&cari=${data.cari}`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  } else if (data.tipe === "parameter") {
    const response = await axios({
      url:
        API_URL +
        `/webParam/getAllParameter?page=${data.page + 1}&limit=${
          data.limit
        }&cari=${data.cari}`,
      method: "get",
      headers: { token: token },
    })
    return response.data
  }
}

const getJsonElement = async () => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTugas/getAllJson`,
    method: "get",
    headers: { token: token },
  })
  return response.data
}

const postAddTugas = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTugas/tambahTugas`,
    method: "post",
    headers: { token: token },
    data: data,
  })
  return response.data
}

const getTugasBe = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url:
      API_URL +
      `/webTugas/getAllTugas?page=${data.page + 1}&limit=${data.limit}&cari=${
        data.cari
      }`,
    method: "get",
    headers: { token: token },
  })
  return response.data
}

const getLaporan = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url:
      API_URL +
      `/webLaporan/getAllLaporan?page=${data.page + 1}&limit=${
        data.limit
      }&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${
        data.tglAkhir
      }&periode=${data.periode}`,
    method: "get",
    headers: { token: token },
  })
  return response.data
}

const getTemuan = async data => {
  const response = await axios({
    url:
      API_URL +
      `/webTemuan/getAllTemuanByIdRuangan?idRuangan=${data.idRuangan}&page=${
        data.page + 1
      }&limit=${data.limit}&cari=${data.cari}`,
    method: "get",
  })
  return response.data
}

const postAddTemuan = async data => {
  const response = await axios({
    url: API_URL + `/webTemuan/buatTemuan`,
    method: "post",
    data: data,
  })
  return response.data
}

const getRuanganTemuanAdmin = async data => {
  const response = await axios({
    url:
      API_URL +
      `/webTemuan/getAllTemuanByGroupRuangan?page=${data.page + 1}&limit=${
        data.limit
      }&cari=${data.cari}`,
    method: "get",
  })
  return response.data
}

const getTemuanAdmin = async data => {
  const response = await axios({
    url:
      API_URL +
      `/webTemuan/getAllTemuanByIdRuangan?idRuangan=${data.idRuangan}&page=${
        data.page + 1
      }&limit=${data.limit}&cari=${data.cari}`,
    method: "get",
  })
  return response.data
}

const postValidasiBe = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTemuan/validasiTemuan`,
    method: "post",
    data: data,
    headers: { token: token },
  })
  return response.data
}

const postSelesaiBe = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTemuan/laporanTemuanSelesai`,
    method: "post",
    data: data,
    headers: { token: token },
  })
  return response.data
}

const donwloadNfc = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `webArea/getNfcArea?idArea=${data}`,
    method: "get",
    headers: { token: token },
  })
  return response.data
}

const deleteTugasBe = async data => {
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTugas/hapusTugas`,
    method: "DELETE",
    data: { idTugas: data },
    headers: { token: token },
  })
  return response.data
}

const deleteTugasLaporanBe = async data => {
  // console.log("KETEMBAK HAPUS TUGAS LAPORAN DI BE >>>>", data)
  const token = localStorage.getItem("authUser")
  const response = await axios({
    url: API_URL + `/webTugas/hapusTugasDanLaporan`,
    method: "DELETE",
    data: { idTugas: data },
    headers: { token: token },
  })
  return response.data
}

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ================== FAKE FAKE FAKE =====================
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const getselectedmails = selectedmails =>
  post(url.GET_SELECTED_MAILS, selectedmails)

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) =>
  post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab })

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST)

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB)

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// add CardData Kanban
export const addCardData = cardData => post(url.ADD_CARD_DATA, cardData)

// update jobs
export const updateCardData = card => put(url.UPDATE_CARD_DATA, card)

// delete Kanban
export const deleteKanban = kanban =>
  del(url.DELETE_KANBAN, { headers: { kanban } })

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job)

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job)

// delete jobs
export const deleteJobList = job =>
  del(url.DELETE_JOB_LIST, { headers: { job } })

// Delete Apply Jobs
export const deleteApplyJob = data =>
  del(url.DELETE_APPLY_JOB, { headers: { data } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get maillist
export const getMailsLists = filter =>
  post(url.GET_MAILS_LIST, {
    params: filter,
  })

export const getMailsListsId = id =>
  api.get(`${url.GET_MAILS_ID}/${id}`, { params: { id } })

//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail)

// delete
export const deleteMail = mail => del(url.DELETE_MAIL, { headers: { mail } })
export const trashMail = mail => del(url.TRASH_MAIL, { headers: { mail } })
export const staredMail = mail => del(url.STARED_MAIL, { headers: { mail } })

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER)

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// delete message
export const deleteMessage = data =>
  del(url.DELETE_MESSAGE, { headers: { data } })

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } })

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } })

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } })

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  postLogin,
  postRefreshWeb,
  postAddElement,
  getElement,
  getJsonElement,
  postAddTugas,
  getTugasBe,
  getLaporan,
  getTemuan,
  postAddTemuan,
  getRuanganTemuanAdmin,
  getTemuanAdmin,
  postValidasiBe,
  postSelesaiBe,
  donwloadNfc,
  deleteTugasBe,
  deleteTugasLaporanBe,
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
