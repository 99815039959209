import {
  GET_LAPORAN,
  GET_LAPORAN_FAIL,
  GET_LAPORAN_SUCCESS,
} from "./actionTypes"

export const getLaporan = data => {
  return {
    type: GET_LAPORAN,
    payload: data,
  }
}

export const getLaporanSuccess = data => {
  return {
    type: GET_LAPORAN_SUCCESS,
    payload: data,
  }
}

export const getLaporanFail = data => {
  return {
    type: GET_LAPORAN_FAIL,
    payload: data,
  }
}
