import {
  ADD_ELEMENT,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_FAIL,
  GET_ELEMENT,
  GET_ELEMENT_SUCCESS,
  GET_ELEMENT_FAIL,
  CHANGE_CARI,
  CHANGE_LIMIT,
  CHANGE_PAGE,
  CHANGE_CARI_SUCCSESS,
  CHANGE_LIMIT_SUCCSESS,
  CHANGE_PAGE_SUCCSESS,
} from "./actionTypes"

const initialState = {
  loadingAdd: false,
  loadingGet: false,
  errorAdd: null,
  errorGet: null,
  elements: null,
  page: 0,
  limit: 10,
  cari: "",
}

const elemen = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ELEMENT:
      state = {
        ...state,
        loadingAdd: true,
      }
      break
    case ADD_ELEMENT_SUCCESS:
      state = {
        ...state,
        loadingAdd: false,
      }
      break
    case ADD_ELEMENT_FAIL:
      state = {
        ...state,
        loadingAdd: false,
        errorAdd: action.payload,
      }
      break
    case GET_ELEMENT:
      state = {
        ...state,
        loadingGet: true,
        page: action.payload.page,
        limit: action.payload.limit,
        cari: action.payload.cari,
      }
      break
    case GET_ELEMENT_SUCCESS:
      state = {
        ...state,
        loadingGet: false,
        elements: action.payload,
      }
      break
    case GET_ELEMENT_FAIL:
      state = {
        ...state,
        loadingGet: false,
        errorGet: action.payload,
      }
      break
    case CHANGE_CARI:
      state = {
        ...state,
        loadingGet: true,
        cari: action.payload,
      }
    case CHANGE_PAGE:
      state = {
        ...state,
        loadingGet: true,
        page: action.payload.page,
      }
    case CHANGE_LIMIT:
      state = {
        ...state,
        loadingGet: true,
        limit: action.payload,
      }
    case CHANGE_LIMIT_SUCCSESS:
      state = {
        ...state,
        loadingGet: false,
      }
    case CHANGE_PAGE_SUCCSESS:
      state = {
        ...state,
        loadingGet: false,
      }
    case CHANGE_CARI_SUCCSESS:
      state = {
        ...state,
        loadingGet: false,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default elemen
