import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  ModalFooter,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  getRuanganAdmin,
  getTemuanAdmin,
  clearTemuan,
  postValidasi,
  postSelesai,
  testPost,
} from "store/actions"
import { ToastContainer } from "react-toastify"
import DataTable from "./dataTableRuangan"
import DataTableTemuan from "./dataTableTemuan"
import moment from "moment"
import "moment/locale/id"

const TemuanAdmin = () => {
  document.title = "BAP"
  moment.locale("id")
  const dispatch = useDispatch()
  const ruanganAdmin = useSelector(state => state.temuanAdmin.ruangan)
  const temuanAdmin = useSelector(state => state.temuanAdmin.temuan)
  const loadingTemuan = useSelector(state => state.temuanAdmin.loadingGetTemuan)
  const loadingRuangan = useSelector(
    state => state.temuanAdmin.loadingGetRuangan
  )
  const loadingPostValidasi = useSelector(
    state => state.temuanAdmin.loadingPostValidasi
  )
  const loadingPostSelesai = useSelector(
    state => state.temuanAdmin.loadingPostSelesai
  )
  const pageRuangan = useSelector(state => state.temuanAdmin.pageRuangan)
  const limitRuangan = useSelector(state => state.temuanAdmin.limitRuangan)
  const cariRuangan = useSelector(state => state.temuanAdmin.cariRuangan)
  const pageTemuan = useSelector(state => state.temuanAdmin.pageTemuan)
  const limitTemuan = useSelector(state => state.temuanAdmin.limitTemuan)
  const cariTemuan = useSelector(state => state.temuanAdmin.cariTemuan)
  // console.log("ruanganAdmin >>>", ruanganAdmin)
  // console.log("temuanAdmin >>>", temuanAdmin)
  const [modal, setModal] = useState(false)
  const [modalForm, setModalForm] = useState(false)
  const [namaRuangan, setNamaRuangan] = useState("")
  const [dataModal, setDataModal] = useState(null)
  const [validasiVal, setValidasiVal] = useState("")
  const [idTemuan, setIdTemuan] = useState("")

  const tglValidasi = new Date().getTime()
  const tglValidasiFix = tglValidasi.toString()
  const tglDeadlineProses = new Date(
    dataModal !== null ? dataModal.tglDeadlineProses : tglValidasi
  ).getTime()
  const tglDeadlineProsesFix = tglDeadlineProses.toString()

  const formatDate = dateString => {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date)
  }

  const column = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Ruangan",
      accessorFn: row => row.namaRuangan,
    },
    {
      header: "Total temuan",
      accessorFn: row => row.totalTemuan,
    },
    {
      header: "Temuan menunggu",
      accessorFn: row => row.totalTemuanBelumDikerjakan,
    },
    {
      header: "Temuan diproses",
      accessorFn: row => row.totalTemuanDiproses,
    },
    {
      header: "Temuan selesai",
      accessorFn: row => row.totalTemuanSelesai,
    },
    {
      header: "Detail",
      // cell: ({ row }) => <span>Detail Button</span>,
      cell: ({ row }) => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              //   setSelectedImage(row.foto_laporan)
              //   toggleModal()
              // console.log("AMBIL ID RUANGAN BOLO ????", row)
              setNamaRuangan(row.namaRuangan)
              //   dispatch(clearTemuan())
              dispatch(
                getTemuanAdmin({
                  idRuangan: row.idRuangan,
                  limit: limitTemuan,
                  cari: cariTemuan,
                  page: pageTemuan,
                })
              )
            }}
          >
            Lihat
          </Button>
        )
      },
    },
  ]

  const columnTemuan = [
    {
      header: "No",
      accessorFn: (_, rowIndex) => rowIndex + 1,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "id Temuan",
      accessorFn: row => row.idTemuan,
    },
    {
      header: "Pelapor",
      accessorFn: row => row.nama,
    },
    {
      header: "Tanggal melapor",
      accessorFn: row => moment(row.tglMelapor).format("LLL"),
    },
    {
      header: "Status",
      //   accessorFn: row => row.statusTemuan,
      cell: ({ row }) => {
        return (
          <Button
            type="button"
            color={
              row.statusTemuan === "Belum Dikerjakan"
                ? "warning"
                : row.statusTemuan === "Diproses"
                ? "light"
                : row.statusTemuan === "Selesai"
                ? "success"
                : "secondary"
            }
            className="btn-sm btn-rounded"
          >
            {row.statusTemuan === "Belum Dikerjakan"
              ? "Menunggu"
              : row.statusTemuan}
          </Button>
        )
      },
    },
    {
      header: "Detail",
      // cell: ({ row }) => <span>Detail Button</span>,
      cell: ({ row }) => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              setModal(true)
              // console.log("row >>>", row)
              setDataModal(row)
              setIdTemuan(row.idTemuan)
              validation.setFieldValue("idTemuan", row.idTemuan)
              validationSelesai.setFieldValue("idTemuan", row.idTemuan)
              // console.log("tglDeadlineProses >>>", tglDeadlineProses)
              // console.log("tglValidasi >>>", tglValidasi)
            }}
          >
            Lihat
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(
      getRuanganAdmin({
        limit: limitRuangan,
        cari: cariRuangan,
        page: pageRuangan,
      })
    )
  }, [])

  const displayValue = value => (value === "" ? "-" : value)

  const listValidasi = [
    { key: 0, label: "Ringan", value: "Ringan" },
    { key: 1, label: "Sedang", value: "Sedang" },
    { key: 2, label: "Berat", value: "Berat" },
  ]

  const validation = useFormik({
    // enableReinitialize: true,
    initialValues: {
      idTemuan: "",
      kategoriTemuan: validasiVal,
    },

    validationSchema: Yup.object({
      idTemuan: Yup.string().required("Harap isi id temuan"),
      kategoriTemuan: Yup.string().required("Harap isi kategori"),
    }),

    onSubmit: values => {
      // console.log("values validation >>>", values)
      const valuesFix = {
        ...values,
        tglValidasi: tglValidasiFix,
        tglDeadlineProses: tglDeadlineProsesFix,
      }
      // console.log("valuesFix >>>", valuesFix)
      dispatch(postValidasi(valuesFix))
      setModal(false)
      setModalForm(false)
      validation.resetForm()
    },
  })

  const validationSelesai = useFormik({
    // enableReinitialize: true,
    initialValues: {
      idTemuan: "",
      hasil: "",
      fotoSesudah: null,
    },

    validationSchema: Yup.object({
      idTemuan: Yup.string().required("Harap isi id temuan"),
      hasil: Yup.string().required("Harap isi hasil"),
      fotoSesudah: Yup.mixed().required("Harap upload foto temuan"),
    }),

    onSubmit: values => {
      // console.log("values validation >>>", values)
      const formData = new FormData()
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key])
        }
      }
      formData.append("tglSelesai", tglValidasiFix)
      formData.append("tglDeadline", tglDeadlineProsesFix)
      // console.log("formData >>>", formData)

      dispatch(postSelesai(formData))
      setModal(false)
      setModalForm(false)
      validationSelesai.resetForm()
    },
  })

  return (
    // <></>
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kebersihan" breadcrumbItem="Temuan" />
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {temuanAdmin !== null ? (
                        <div className="flex-grow-1">
                          <div className="d-flex align-items-start">
                            <button
                              className="btn p-0"
                              onClick={() => dispatch(clearTemuan())}
                              style={{ background: "none", border: "none" }} // No button styling, just the icon
                            >
                              <i
                                className="mdi mdi-keyboard-backspace"
                                style={{ fontSize: "24px" }}
                              />
                            </button>
                            <div className="ms-3">
                              <CardTitle className="h4">
                                Temuan di {namaRuangan}
                              </CardTitle>
                              <CardSubtitle className="card-title-desc">
                                Daftar temuan dari {namaRuangan}
                              </CardSubtitle>
                            </div>
                          </div>
                          <Row className="text-center justify-content-center align-items-center">
                            {loadingTemuan ||
                            temuanAdmin === null ||
                            loadingPostValidasi ||
                            loadingPostSelesai ? (
                              <Spinner size="lg" color="primary" />
                            ) : (
                              <DataTableTemuan
                                columns={columnTemuan}
                                tableClass="table-striped"
                                theadClass="thead-dark"
                                divClassName="table-responsive-sm"
                                isBordered={true}
                                isPagination={true}
                                isGlobalFilter={true}
                                paginationWrapper="pagination-wrapper"
                                SearchPlaceholder="Cari id temuan ..."
                                buttonClass="btn-success"
                                buttonName="Cari"
                                isAddButton={true}
                                isCustomPageSize={true}
                                handleUserClick={() => console.log("yuhu")}
                                isJobListGlobalFilter={false}
                              />
                            )}
                          </Row>
                        </div>
                      ) : (
                        <div className="flex-grow-1">
                          <CardTitle className="h4">Daftar temuan</CardTitle>
                          <CardSubtitle className="card-title-desc">
                            Daftar temuan berdasarkan ruangan
                          </CardSubtitle>
                          <Row className="text-center justify-content-center align-items-center">
                            {loadingRuangan ||
                            ruanganAdmin === null ||
                            loadingTemuan ? (
                              <Spinner size="lg" color="primary" />
                            ) : (
                              <DataTable
                                columns={column}
                                tableClass="table-striped"
                                theadClass="thead-dark"
                                divClassName="table-responsive-sm"
                                isBordered={true}
                                isPagination={true}
                                isGlobalFilter={true}
                                paginationWrapper="pagination-wrapper"
                                SearchPlaceholder="Cari ruangan ..."
                                buttonClass="btn-success"
                                buttonName="Cari"
                                isAddButton={true}
                                isCustomPageSize={true}
                                handleUserClick={() => console.log("yuhu")}
                                isJobListGlobalFilter={false}
                              />
                            )}
                          </Row>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {dataModal !== null ? (
        <>
          <Modal
            isOpen={modal}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            toggle={() => {
              setModal(!modal)
            }}
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  setModal(!modal)
                }}
              ></ModalHeader>
            </div>
            <ModalBody>
              <div className="text-center mb-2">
                <h4 className="modal-title mb-4">Detail Temuan</h4>

                <Row className="justify-content-center">
                  <Col md="6">
                    <div className="mb-3">
                      <strong>ID Temuan: </strong>
                      {displayValue(dataModal.idTemuan)}
                    </div>
                    <div className="mb-3">
                      <strong>Ruangan: </strong>
                      {displayValue(dataModal.ruangan.namaRuangan)}
                    </div>
                    <div className="mb-3">
                      <strong>Keluhan: </strong>
                      {displayValue(dataModal.keluhan)}
                    </div>
                    <div className="mb-3">
                      <strong>Nama: </strong>
                      {displayValue(dataModal.nama)}
                    </div>
                    <div className="mb-3">
                      <strong>Kategori: </strong>
                      {displayValue(dataModal.kategori)}
                    </div>
                    <div className="mb-3">
                      <strong>Instansi: </strong>
                      {displayValue(dataModal.instansi)}
                    </div>
                    <div className="mb-3">
                      <strong>Jabatan: </strong>
                      {displayValue(dataModal.jabatan)}
                    </div>
                    <div className="mb-3">
                      <strong>No. Pegawai: </strong>
                      {displayValue(dataModal.nopeg)}
                    </div>
                    <div className="mb-3">
                      <strong>Tanggal Melapor: </strong>
                      {moment(dataModal.tglMelapor).format("YYYY-MM-DD")}
                    </div>
                    <div className="mb-3">
                      <strong>Deadline Proses: </strong>
                      {moment(dataModal.tglDeadlineProses).format("YYYY-MM-DD")}
                    </div>
                  </Col>
                  <Col md="6">
                    <img
                      src={dataModal.foto_sebelum}
                      alt="Foto Sebelum"
                      className="img-fluid rounded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "300px",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
                {dataModal.statusTemuan === "Belum Dikerjakan" ||
                dataModal.statusTemuan === "Diproses" ? (
                  <Row className="mt-4">
                    <Col md="12" className="d-flex">
                      <Button
                        type="button"
                        color="secondary"
                        className="btn-md  flex-grow-1"
                        onClick={() => {
                          setModalForm(true)
                        }}
                      >
                        {dataModal.statusTemuan === "Belum Dikerjakan"
                          ? "Validasi"
                          : dataModal.statusTemuan === "Diproses"
                          ? "Selesaikan"
                          : ""}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalForm}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            toggle={() => {
              setModalForm(!modalForm)
            }}
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  setModalForm(!modalForm)
                }}
              ></ModalHeader>
            </div>
            <ModalBody>
              <div className=" mb-2">
                <h4 className="modal-title mb-4 text-center">
                  {dataModal.statusTemuan === "Belum Dikerjakan"
                    ? "Validasi"
                    : "Selesaikan"}{" "}
                  Temuan
                </h4>
                {dataModal.statusTemuan === "Belum Dikerjakan" ? (
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <select
                      id="elemen-selector"
                      className="form-select"
                      defaultValue={validasiVal}
                      value={validation.values.kategoriTemuan}
                      onChange={event => {
                        let seletedValue = event.target.value
                        // console.log("seletedValue >>>", seletedValue)
                        validation.setFieldValue("kategoriTemuan", seletedValue)
                      }}
                    >
                      <option value="" disabled>
                        -- Pilih kategori --
                      </option>
                      {(listValidasi || [])?.map(val => (
                        <option key={val.key} value={val.value}>
                          {val.label}
                        </option>
                      ))}
                    </select>

                    <div className="mt-5 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        onClick={() => {
                          // console.log("MASUK SINI GA BOLO")
                          // console.log(
                          //   "validation.errors >>>",
                          //   validation.errors
                          // )
                          // console.log("dataModal >>>", dataModal)
                        }}
                        // disabled={loadingAddElemen}
                      >
                        {/* Tambah */}
                        {/* {loadingAddElemen ? (
                                <Spinner size="sm" />
                              ) : (
                                "Buat"
                              )} */}
                        Simpan
                      </button>
                    </div>
                  </Form>
                ) : (
                  <Form
                    className="form-horizontal"
                    // onSubmit={e => {
                    //   e.preventDefault()
                    //   validationSelesai.handleSubmit()
                    //   return false
                    // }}
                    onSubmit={validationSelesai.handleSubmit}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Hasil</Label>
                      <Input
                        name="hasil"
                        className="form-control"
                        placeholder="Masukkan hasil"
                        type="text"
                        onChange={validationSelesai.handleChange}
                        onBlur={validationSelesai.handleBlur}
                        value={validationSelesai.values.hasil || ""}
                        invalid={
                          validationSelesai.touched.hasil &&
                          validationSelesai.errors.hasil
                            ? true
                            : false
                        }
                      />
                      {validationSelesai.touched.hasil &&
                      validationSelesai.errors.hasil ? (
                        <FormFeedback type="invalid">
                          {validationSelesai.errors.hasil}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Foto setelah selesai</Label>
                      <Input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={e => {
                          // console.log(e.target.files[0])
                          // setFotoTemuan(e.target.files[0])
                          validationSelesai.setFieldValue(
                            "fotoSesudah",
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>

                    <div className="mt-5 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        onClick={() => {
                          // console.log("MASUK SINI GA BOLO")
                          // console.log(
                          //   "validationSelesai.errors >>>",
                          //   validationSelesai.errors
                          // )
                          // console.log("dataModal >>>", dataModal)
                        }}
                        // disabled={loadingAddElemen}
                      >
                        {/* Tambah */}
                        {/* {loadingAddElemen ? (
                                <Spinner size="sm" />
                              ) : (
                                "Buat"
                              )} */}
                        Simpan
                      </button>
                    </div>
                  </Form>
                )}
              </div>

              <Row></Row>
            </ModalBody>
          </Modal>
        </>
      ) : null}

      <ToastContainer />
    </React.Fragment>
  )
}

export default TemuanAdmin
