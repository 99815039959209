import {
  GET_TEMUAN,
  GET_TEMUAN_SUCCESS,
  GET_TEMUAN_FAIL,
  GET_RUANGAN,
  GET_RUANGAN_SUCCESS,
  GET_RUANGAN_FAIL,
  CLEAR_TEMUAN,
  POST_VALIDASI,
  POST_VALIDASI_SUCCESS,
  POST_VALIDASI_FAIL,
  POST_SELESAI,
  POST_SELESAI_SUCCESS,
  POST_SELESAI_FAIL,
} from "./actionTypes"

export const getRuanganAdmin = data => {
  return {
    type: GET_RUANGAN,
    payload: data,
  }
}

export const getRuanganAdminSuccess = data => {
  return {
    type: GET_RUANGAN_SUCCESS,
    payload: data,
  }
}

export const getRuanganAdminFail = data => {
  return {
    type: GET_RUANGAN_FAIL,
    payload: data,
  }
}

export const getTemuanAdmin = data => {
  return {
    type: GET_TEMUAN,
    payload: data,
  }
}

export const getTemuanAdminSuccess = data => {
  return {
    type: GET_TEMUAN_SUCCESS,
    payload: data,
  }
}

export const getTemuanAdminFail = data => {
  return {
    type: GET_TEMUAN_FAIL,
    payload: data,
  }
}

export const clearTemuan = () => {
  return {
    type: CLEAR_TEMUAN,
  }
}

export const postValidasi = data => {
  return {
    type: POST_VALIDASI,
    payload: data,
  }
}

export const postValidasiSuccess = data => {
  return {
    type: POST_VALIDASI_SUCCESS,
    payload: data,
  }
}

export const postValidasiFail = data => {
  return {
    type: POST_VALIDASI_FAIL,
    payload: data,
  }
}

export const postSelesai = data => {
  return {
    type: POST_SELESAI,
    payload: data,
  }
}

export const testPost = data => {
  console.log("test", data)
}

export const postSelesaiSuccess = data => {
  return {
    type: POST_SELESAI_SUCCESS,
    payload: data,
  }
}

export const postSelesaiFail = data => {
  return {
    type: POST_SELESAI_FAIL,
    payload: data,
  }
}
